import { getDistance } from 'geolib'
import { GPOS, log } from '@/utils'
import GeoTag from '@/model/geotag'

import { gmapApi } from 'vue2-google-maps'
import L from 'leaflet'

export default {
  namespaced: true,
  state: {
    google: false,
    leaflet: false,
    streetView: {},
    mapPathLines: [],
    mapPathDots: [],
    theMarker: false,
    poiMarkers: [],
    povMarkers: [],
    photoMarkers: [],
    selectedMarkers: [],
    otherMarkers: [],
    mapPathVideos: [],
    videoMarkers: [],
    mapButtons: []
  },
  getters: {
    bounds(state) {
      if (state.google && state.google.map) {
        return state.google.map.getBounds()
      } else if (state.leaflet && state.leaflet.map) {
        return state.leaflet.map.getBounds()
      }
      return null
    }
  },
  mutations: {
    SET_GOOGLE_MAP(state, map) {
      /* eslint-disable new-cap */
      log('GOOGLE MAP INIT')

      const api = gmapApi().maps
      log('GOOGLE API:', api)
      state.google = {
        map,
        api,
        // autocomplete: new api.places.AutocompleteService(),
        // places: new api.places.PlacesService(map),
        directions: new api.DirectionsService(),
        sessionToken: false
      }
      state.leaflet = false
      /* eslint-enable new-cap */
    },
    SET_LEAFLET_MAP(state, map) {
      log('LEAFLET MAP INIT')
      state.leaflet = {
        map
      }
      state.google = false
    },
    SET_PATH_LINES(state, val) {
      state.mapPathLines = val || []
    },
    SET_PATH_DOTS(state, val) {
      state.mapPathDots = val || []
    },
    SET_PATH_VIDEOS(state, val) {
      state.mapPathVideos = val || []
    },
    SET_THE_MARKER(state, val) {
      state.theMarker = val || false
    },
    SET_POI_MARKERS(state, val) {
      state.poiMarkers = val || []
    },
    SET_POV_MARKERS(state, val) {
      state.povMarkers = val || []
    },
    SET_PHOTO_MARKERS(state, val) {
      state.photoMarkers = val || []
    },
    SET_SELECTED_MARKERS(state, val) {
      state.selectedMarkers = val || []
    },
    SET_OTHER_MARKERS(state, val) {
      state.otherMarkers = val || []
    },
    SET_VIDEO_MARKERS(state, val) {
      state.videoMarkers = val || []
    },
    SET_MAP_BUTTONS(state, val) {
      state.mapButtons = val || []
    }
  },
  actions: {
    reset({ state }) {
      state.mapPathLines = []
      state.mapPathDots = []
      state.theMarker = false
      state.poiMarkers = []
      state.povMarkers = []
      state.photoMarkers = []
      state.selectedMarkers = []
      state.otherMarkers = []
      state.mapPathVideos = []
      state.videoMarkers = []
      state.mapButtons = []
    },
    changeMapSize({ state }) {
      if (state.leaflet && state.leaflet.map) {
        state.leaflet.map.invalidateSize(true)
      }
    },
    positionInBounds({ state }, position) {
      if (position) {
        if (state.google && state.google.map) {
          return state.google.map.getBounds().contains(position)
        } else if (state.leaflet && state.leaflet.map) {
          return state.leaflet.map.getBounds().contains(position)
        }
      }
      return null
    },
    panToPosition({ state }, position) {
      if (state.google && state.google.map) {
        state.google.map.panTo(position)
      } else if (state.leaflet && state.leaflet.map) {
        state.leaflet.map.panTo(position)
      }
    },
    fitBounds({ state }, points) {
      if (state.google && state.google.map) {
        // eslint-disable-next-line new-cap
        const bounds = new state.google.api.LatLngBounds()
        for (let n = 0; n < points.length; n++) {
          bounds.extend(points[n])
        }
        state.google.map.fitBounds(bounds)
      } else if (state.leaflet && state.leaflet.map) {
        // eslint-disable-next-line new-cap
        const bounds = new L.latLngBounds(points)
        state.leaflet.map.fitBounds(bounds)
      }
    },
    setCenter({ state }, val) {
      if (state.google && state.google.map) {
        state.google.map.setCenter(val)
      } else if (state.leaflet && state.leaflet.map) {
        state.leaflet.map.panTo(val)
      }
    },
    setZoom({ state }, val) {
      if (state.google && state.google.map) {
        state.google.map.setZoom(val)
      } else if (state.leaflet && state.leaflet.map) {
        state.leaflet.map.setZoom(val)
      }
    },
    setView({ state }, { center, zoom, bounds }) {
      if (state.google && state.google.map) {
        if (bounds) {
          state.google.map.fitBounds(
            {
              east: bounds.ne.lng,
              north: bounds.ne.lat,
              south: bounds.sw.lat,
              west: bounds.sw.lng
            },
            0
          )
        } else {
          state.google.map.setCenter(center)
          state.google.map.setZoom(zoom)
        }
      } else if (state.leaflet && state.leaflet.map) {
        if (bounds) {
          state.leaflet.map.fitBounds(L.latLngBounds(bounds.sw, bounds.ne))
        } else {
          state.leaflet.map.setView(center, zoom)
        }
      }
    },
    toggleStreetView({ state }, visible) {
      if (state.google) {
        state.google.map.getStreetView().setVisible(visible)
      } else if (state.leaflet && state.leaflet.map) {
        this.commit('ui/TOGGLE_STREET_VIEW', visible)
      }
    },
    setStreetView({ state }, { position, pov }) {
      state.streetView = { position, pov }
      if (state.google) {
        if (position) state.google.map.getStreetView().setPosition(position)
        if (pov) state.google.map.getStreetView().setPov(pov)
      }
    },
    showRoute({ state }, { tags, mode = 'DRIVING' }) {
      /* eslint-disable new-cap */
      if (tags.length < 2) {
        return null
      }

      if (state.google) {
        return new Promise((resolve, reject) => {
          const len = tags.length
          const origin = tags[0].position
          const destination = tags[len - 1].position
          const waypoints = tags.slice(1, len - 1).map(tag => ({
            location: tag.position,
            stopover: true
          }))

          state.google.directions.route(
            {
              origin,
              destination,
              waypoints,
              travelMode: mode
            },
            (response, status) => {
              if (status === 'OK') {
                log(response)

                const route = []
                const legs = response.routes[0].legs
                let tagsCount = 0
                for (let i = 0; i < legs.length; i++) {
                  const leg = legs[i]
                  const seg = {
                    from: tags[i].seconds,
                    to: tags[i + 1].seconds
                  }
                  const steps = []
                  leg.steps.forEach(step => {
                    step.path.forEach(latLng => {
                      steps.push({
                        position: {
                          lat: latLng.lat(),
                          lng: latLng.lng()
                        }
                      })
                    })
                  })
                  if (steps.length > 0) {
                    steps[0].distance = getDistance(GPOS(tags[i].position), GPOS(steps[0].position))
                    for (let j = 1; j < steps.length; j++) {
                      steps[j].distance =
                        getDistance(GPOS(steps[j - 1].position), GPOS(steps[j].position)) + steps[j - 1].distance
                    }
                    const totalDistance = steps[steps.length - 1].distance
                    const totalSeconds = seg.to - seg.from
                    for (let j = 0; j < steps.length; j++) {
                      steps[j].seconds = Math.floor((steps[j].distance / totalDistance) * totalSeconds) + seg.from
                      if (j === 0 || j === steps.length - 1) {
                        steps[j].ds = 0
                      } else {
                        steps[j].ds = steps[j].seconds - steps[j - 1].seconds
                      }
                    }
                    // TODO: reduce tags count
                    seg.tags = steps.filter(step => step.ds > 0).map(step => new GeoTag(step))
                    tagsCount += seg.tags.length
                  }

                  route.push(seg)
                }

                if (tagsCount > 0) {
                  const renderer = new state.google.api.DirectionsRenderer({ map: state.google.map })
                  renderer.setDirections(response)
                  state.google.directionsRenderer = renderer

                  return resolve(route)
                } else {
                  log('no tags to add, time less then 1s')
                  return resolve([])
                }
              } else {
                log(`Directions request failed due to ${status}`)
                return reject()
              }
            }
          )
        })
      }

      return null
      /* eslint-enable new-cap */
    },
    hideRoute({ state }) {
      if (state.google && state.google.directionsRenderer) {
        state.google.directionsRenderer.setMap()
        state.google.directionsRenderer = false
      }
    }
  }
}
