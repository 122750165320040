var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('gmap-map',{ref:"map",attrs:{"center":_vm.center,"zoom":_vm.zoom,"options":_vm.mapOptions},on:{"click":function (ref) {
    var latLng = ref.latLng;
    var placeId = ref.placeId;
    var domEvent = ref.domEvent;

    return _vm.$emit('click-map', latLng.toJSON(), placeId, domEvent);
},"center_changed":function (latLng) { return _vm.$emit('change-map-center', latLng.toJSON()); },"zoom_changed":function (val) { return _vm.$emit('change-zoom', val); }}},[_vm._l((_vm.mapPathLines),function(path,index){return _c('gmap-polyline',{key:("line" + index),attrs:{"path":path,"editable":false,"options":_vm.pathLine},on:{"click":function (ref) {
    var latLng = ref.latLng;

    return _vm.$emit('click-path', latLng.toJSON());
}}})}),_vm._l((_vm.mapPathDots),function(path,index){return _c('gmap-polyline',{key:("dot" + index),attrs:{"path":path,"editable":false,"options":_vm.pathDot},on:{"click":function (ref) {
    var latLng = ref.latLng;

    return _vm.$emit('click-path', latLng.toJSON());
}}})}),_vm._l((_vm.poiMarkers),function(m,index){return _c('gmap-marker',{key:("poiMarker" + index),attrs:{"position":m.position,"clickable":true,"draggable":_vm.showSidebarRightEditTags && !_vm.playVideo,"icon":_vm.poiMarkerIcon},on:{"click":function($event){return _vm.$emit('click-poi-marker', m)},"dragend":function (ref) {
    var latLng = ref.latLng;

    return _vm.$emit('move-tag-marker', m, latLng.toJSON());
}}})}),_vm._l((_vm.photoMarkers),function(m,index){return _c('gmap-marker',{key:("photoMarker" + index),attrs:{"position":m.position,"clickable":true,"draggable":_vm.showSidebarRightEditTags && !_vm.playVideo,"icon":_vm.photoMarkerIcon},on:{"click":function($event){return _vm.$emit('click-photo-marker', m)},"dragend":function (ref) {
    var latLng = ref.latLng;

    return _vm.$emit('move-tag-marker', m, latLng.toJSON());
}}})}),_vm._l((_vm.povMarkers),function(m,index){return _c('gmap-marker',{key:("povMarker" + index),attrs:{"position":m.position,"clickable":true,"draggable":_vm.showSidebarRightEditTags && !_vm.playVideo,"icon":_vm.povMarkerIcon},on:{"click":function($event){return _vm.$emit('click-pov-marker', m)},"dragend":function (ref) {
    var latLng = ref.latLng;

    return _vm.$emit('move-tag-marker', m, latLng.toJSON());
}}})}),_vm._l((_vm.mapPathVideos),function(path,index){return _c('gmap-polyline',{key:("line" + index),attrs:{"path":path,"editable":false,"options":_vm.pathLineOptions(index)},on:{"click":function($event){return _vm.$emit('click-video', index)},"mouseover":function($event){_vm.focusIndex = index},"mouseout":function($event){_vm.focusIndex = -1}}})}),_vm._l((_vm.videoMarkers),function(m,index){return _c('gmap-marker',{key:("videoMarker" + index),attrs:{"position":m.position,"clickable":true,"draggable":false,"icon":_vm.pathMarkerIcon(index),"z-index":_vm.focusIndex === index ? 1 : 0},on:{"click":function($event){return _vm.$emit('click-video', index)},"mouseover":function($event){_vm.focusIndex = index},"mouseout":function($event){_vm.focusIndex = -1}}})}),(_vm.showSidebarRightEditTags)?_c('div',_vm._l((_vm.selectedMarkers),function(m,index){return _c('gmap-marker',{key:("selectedMarker" + index),attrs:{"position":m.position,"clickable":true,"draggable":_vm.showSidebarRightEditTags && !_vm.playVideo,"icon":_vm.selectedMarkerIcon},on:{"click":function($event){return _vm.$emit('click-tag-marker', m)},"dragend":function (ref) {
    var latLng = ref.latLng;

    return _vm.$emit('move-tag-marker', m, latLng.toJSON());
}}})}),1):_vm._e(),(_vm.showSidebarRightEditTags && !_vm.playVideo)?_c('GmapCluster',_vm._l((_vm.otherMarkers),function(m,index){return _c('gmap-marker',{key:("otherMarker" + index),attrs:{"position":m.position,"clickable":true,"draggable":_vm.showSidebarRightEditTags && !_vm.playVideo,"icon":_vm.otherMarkerIcon},on:{"click":function($event){return _vm.$emit('click-tag-marker', m)},"dragend":function (ref) {
    var latLng = ref.latLng;

    return _vm.$emit('move-tag-marker', m, latLng.toJSON());
}}})}),1):_vm._e(),(_vm.theMarker)?_c('gmap-marker',{key:"theMarker",attrs:{"position":_vm.theMarker.position,"icon":_vm.pathMarkerIcon(),"clickable":false,"draggable":true},on:{"dragend":function (ref) {
    var latLng = ref.latLng;

    return _vm.$emit('move-the-marker', latLng.toJSON());
}}}):_vm._e(),(_vm.showingPoi)?_c('gmap-info-window',{attrs:{"options":{
      maxWidth: 300,
      pixelOffset: { width: 0, height: 0 },
    },"position":_vm.showingPoi.location}},[_vm._v(" "+_vm._s(_vm.showingPoi.n)+" "),_c('a',{attrs:{"href":_vm.showingPoi.url,"target":"_blank"}},[_vm._v("Open...")])]):_vm._e(),_vm._l((_vm.mapButtons),function(m,index){return _c('google-map-button',{key:("mapButtons" + index)},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:(m.tooltip),expression:"m.tooltip",modifiers:{"hover":true,"bottom":true}}],staticClass:"btn-icon",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$emit(m.event)}}},[_c('feather-icon',{attrs:{"icon":m.icon}})],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }