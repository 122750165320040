<template>
  <b-card id="edit-tags-table" no-body>
    <b-card-header>
      <b-card-title>Tags</b-card-title>
      <!-- <b-card-sub-title>Edit geo tags</b-card-sub-title> -->

      <b-dropdown v-if="!playVideo" variant="link" no-caret toggle-class="p-0 mr-1" right>
        <template #button-content>
          <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
        </template>
        <b-dropdown-item @click="loadFromServer">Reload from server</b-dropdown-item>
        <b-dropdown-item @click="removeAllTags">Remove all tags</b-dropdown-item>
        <b-dropdown-item v-if="isAdmin" @click="lockAllTags">Lock all tags</b-dropdown-item>
        <b-dropdown-item v-if="isAdmin" @click="selectTagsFile">Select tags file</b-dropdown-item>
        <b-dropdown-item v-if="isAdmin" @click="autoRemoveWrongTags">Auto remove wrong tags</b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body>
      <b-row align-v="center">
        <b-link
          v-if="useGoogleMap && isSuperUser && !adjustTime && !playVideo && selected.length >= 2"
          class="card-link"
          @click="$emit('find-route')"
        >
          Find route
        </b-link>

        <b-link
          v-if="!adjustTime && !playVideo && selected.length > 0 && (selected.length > 1 || !selected[0].lock)"
          class="card-link"
          @click="removeTags"
        >
          Remove
        </b-link>

        <b-link
          v-if="!adjustTime && selected.length === 1 && !selected[0].lock && !selected[0].still"
          class="card-link"
          @click="stopTag"
        >
          Stop
        </b-link>
        <b-link
          v-if="!adjustTime && selected.length === 1 && !selected[0].lock && selected[0].still"
          class="card-link"
          @click="moveTag"
        >
          Move
        </b-link>

        <b-link v-if="!adjustTime && selected.length === 1 && !selected[0].lock" class="card-link" @click="lockTag">
          Lock
        </b-link>
        <b-link v-if="!adjustTime && selected.length === 1 && selected[0].lock" class="card-link" @click="unlockTag">
          Unlock
        </b-link>

        <b-link
          v-if="!adjustTime && selected.length === 1 && !selected[0].lock"
          class="card-link"
          @click="$emit('show-gallery')"
        >
          Gallery
        </b-link>

        <b-link
          v-if="selected.length === 1 && selected[0].lock && !adjustTime"
          class="card-link"
          @click="beginAdjustTime"
        >
          Time
        </b-link>

        <b-link v-if="adjustTime" class="card-link" @click="cancelAdjustTime">Cancel</b-link>

        <b-link v-if="adjustTime" class="card-link" @click="confirmAdjustTime">Confirm</b-link>

        <b-form-select
          v-if="selected.length === 1 && (!!selected[0].placeKey || !!selected[0].pov || !!selected[0].photoId)"
          v-model="showTimeSelected"
          class="ml-auto mr-0"
          style="width: 100px"
          :options="showTimeOptions"
          size="sm"
        />
      </b-row>

      <b-time
        v-if="false"
        v-model="value"
        class="rounded mt-1 ml-auto mr-0"
        show-seconds
        hide-header
        :hour12="false"
        locale="en"
      />

      <b-alert
        v-height-fade.appear
        :show="dismissCountDown"
        dismissible
        fade
        variant="warning"
        class="mb-0"
        @dismiss-count-down="countDownChanged"
      >
        <div class="alert-body">
          <span>no tags to add, time is too short</span>
        </div>
      </b-alert>

      <b-card v-if="routeTagCount > 0" no-body class="border-secondary border-lighten-5 compact">
        <b-card-header>
          <b-card-title>Add {{ routeTagCount }} tags?</b-card-title>
          <!-- <b-card-sub-title>Edit geo tags</b-card-sub-title> -->

          <b-button variant="gradient-success" class="btn-icon rounded-circle" @click="$emit('add-route-tags')">
            <feather-icon icon="CheckIcon" />
          </b-button>
          <b-button variant="gradient-danger" class="btn-icon rounded-circle" @click="$emit('clear-route-tags')">
            <feather-icon icon="XIcon" />
          </b-button>
        </b-card-header>
      </b-card>
    </b-card-body>

    <b-table
      ref="tagsTable"
      class="text-center"
      :selectable="!adjustTime || true"
      small
      borderless
      head-variant="light"
      primary-key="key"
      :per-page="perPage"
      :current-page="currentPage"
      :select-mode="selectMode"
      :items="tags"
      :fields="fields"
      @row-selected="onRowSelected"
      @row-clicked="myClickRow"
      @page-change="changePage"
      @keydown.native="doNothing"
    >
      <!-- Example scoped slot for select state illustrative purposes -->
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">x</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only" />
        </template>
      </template>

      <template #cell(lock)="data">
        <feather-icon v-if="data.value" class="cursor-pointer" icon="LockIcon" size="12" />
      </template>

      <template #cell(still)="data">
        <feather-icon v-if="data.value" class="cursor-pointer" icon="CoffeeIcon" size="12" />
      </template>

      <template #cell(avatar)="data">
        <b-avatar :src="data.value" />
      </template>

      <template #cell(type)="data">
        <b-badge :variant="rowType[1][data.value]">
          <feather-icon
            v-b-tooltip.hover.left="rowType[1][data.value] === 'light-secondary' ? 'enable' : 'disable'"
            :icon="`${rowType[0][data.value]}Icon`"
            @click.stop="clickTagEx(data.item)"
          />
        </b-badge>
      </template>
    </b-table>

    <b-card-body class="">
      <!-- pagination -->
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            align="fill"
            size="sm"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card-body>
    <!-- eslint-disable-next-line vue/html-self-closing -->
    <input ref="tagsFile" type="file" style="position: absolute; opacity: 0; z-index: -1" @change="selectedFile" />
  </b-card>
</template>

<script>
import mixinUi from '@/store/ui/mixin'
import mixinTags from '@/store/tags/mixin'
import mixinServer from '@/store/server/mixin'
import {
  BTime,
  BButton,
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BCard,
  BAlert,
  // BCardText,
  BCardBody,
  BCardHeader,
  BCardTitle,
  // BCardSubTitle,
  BLink,
  // BFormGroup,
  BFormSelect,
  // BFormFile,
  BPagination,
  BDropdown,
  BDropdownItem,
  VBTooltip,
} from 'bootstrap-vue'

import store from '@/store'
import { heightFade } from '@core/directives/animations'

import { convertLat, convertLng, fmtTime } from '@/utils'
import GeoTag from '@/model/geotag'

// function fmtLatLng(v) {
//   return Math.floor(v * 1000000) / 1000000
// }

export default {
  components: {
    BTime,
    BButton,
    BAlert,
    BRow,
    BCol,
    BTable,

    BAvatar,
    BBadge,
    BCard,
    // BCardText,
    BCardHeader,
    BCardBody,
    BCardTitle,
    // BCardSubTitle,
    BLink,
    // BFormGroup,
    BFormSelect,
    // BFormFile,
    BPagination,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    heightFade,
    'b-tooltip': VBTooltip,
  },

  mixins: [mixinUi, mixinTags, mixinServer],
  props: {},
  data() {
    return {
      perPage: 10,
      pageOptions: [5, 10, 20],
      currentPage: 1,
      selectedPage: 1,
      selectedRow: 1,
      modes: ['multi', 'single', 'range'],
      fields: [
        // {
        //   key: 'selected',
        //   label: '',
        // },
        {
          key: 'lock',
          label: '',
        },
        {
          key: 'still',
          label: '',
        },
        {
          key: 'seconds',
          label: 'time',
          formatter: fmtTime,
        },

        {
          key: 'position',
          label: 'Position',
          formatter: this.convertPosition,
        },
        {
          key: 'type',
          label: '',
          formatter: this.convertType,
        },
      ],
      rowType: [
        {
          poi: 'Eye',
          pov: 'User',
          photo: 'Image',
          poiDisable: 'Eye',
          povDisable: 'User',
          photoDisable: 'Image',
        },
        {
          poi: 'light-success',
          pov: 'light-warning',
          photo: 'light-info',
          poiDisable: 'light-secondary',
          povDisable: 'light-secondary',
          photoDisable: 'light-secondary',
        },
      ],
      selectMode: 'range',
      selected: [],
      dismissCountDown: 0,
      file2: [],
      showTimeOptions: [
        { value: false, text: 'Show' },
        { value: 5, text: '5 seconds' },
        { value: 15, text: '15 seconds' },
        { value: 30, text: '30 seconds' },
        { value: 45, text: '45 seconds' },
        { value: 60, text: '60 seconds' },
      ],
      value: '',
    }
  },
  computed: {
    totalRows() {
      return this.tags.length
    },
    routeTagCount() {
      if (store.state.tags.route === false) {
        return 0
      }

      if (store.state.tags.route.length === 0) {
        return -1
      }

      let count = 0
      if (store.state.tags.route !== false) {
        store.state.tags.route.forEach(seg => {
          count += seg.tags.length
        })
      }

      return count
    },
    showTimeSelected: {
      get() {
        return this.selected[0].showTime || false
      },
      set(val) {
        store.dispatch('tags/editTag', { tag: this.selected[0], key: 'showTime', value: val })
      },
    },
  },
  watch: {
    tags() {
      setTimeout(() => {
        this.selectPlayingRow()
      }, 100)
    },
    currentIndex() {
      this.selectPlayingRow()
    },
    playVideo(newValue) {
      if (!newValue) {
        this.selectPlayingRow()
      }
    },
    routeTagCount(newValue) {
      this.log(newValue)
      if (newValue === -1) {
        this.dismissCountDown = 5
        store.commit('tags/SET_ROUTE', false)
      }
    },
    currentPage() {
      if (this.currentPage === this.selectedPage) {
        setTimeout(() => {
          this.$refs.tagsTable.selectRow(this.selectedRow)
        }, 100)
      }
    },
  },
  mounted() {},
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    convertPosition(value) {
      return `${convertLat(value.lat)} / ${convertLng(value.lng)}`
    },
    convertType(_, key, row) {
      if (row.placeKey) {
        if (row.disableEx) {
          return 'poiDisable'
        } else {
          return 'poi'
        }
      } else if (row.photoId) {
        if (row.disableEx) {
          return 'photoDisable'
        } else {
          return 'photo'
        }
      } else if (row.pov) {
        if (row.disableEx) {
          return 'povDisable'
        } else {
          return 'pov'
        }
      }
      return null
    },
    myClickRow(row, i, evt) {
      // this.log('click', row, i, evt)
      if (!this.adjustTime && !evt.altKey && !evt.ctrlKey && !evt.shiftKey && !evt.metaKey && evt.type === 'click') {
        // this.clickTag(row)
        this.$emit('click-tag', row)
      }
    },
    selectPlayingRow() {
      if (!this.adjustTime) {
        // this.log('selectPlayingRow')
        this.$refs.tagsTable.clearSelected()

        this.selectedPage = Math.floor(this.currentIndex / this.perPage) + 1
        this.selectedRow = this.currentIndex - (this.selectedPage - 1) * this.perPage

        if (this.currentPage === this.selectedPage) {
          this.$refs.tagsTable.selectRow(this.selectedRow)
        } else if (!this.playVideo) {
          this.currentPage = this.selectedPage
        }
      }
    },
    changePage(page, size) {
      this.log('changePage', page, size)
    },
    onRowSelected(items) {
      if (!this.adjustTime) {
        this.log('select')
        this.selected = items
        this.$emit('row-selected', this.selected)
      }
    },
    removeTags() {
      store.dispatch('tags/removeTags', this.selected)
    },
    removeAllTags() {
      store.dispatch('tags/removeAllTags')
    },
    lockAllTags() {
      this.tags.forEach(tag => {
        store.dispatch('tags/editTag', { tag, key: 'lock', value: true })
      })
    },
    loadFromServer() {
      store.dispatch('tags/loadFromServer')
    },
    lockTag() {
      store.dispatch('tags/editTag', { tag: this.selected[0], key: 'lock', value: true })
    },
    unlockTag() {
      store.dispatch('tags/editTag', { tag: this.selected[0], key: 'lock', value: false })
    },
    stopTag() {
      store.dispatch('tags/editTag', { tag: this.selected[0], key: 'still', value: true })
    },
    moveTag() {
      store.dispatch('tags/editTag', { tag: this.selected[0], key: 'still', value: false })
    },
    selectTagsFile() {
      this.$refs.tagsFile.click()
    },
    selectedFile() {
      const file = this.$refs.tagsFile.files[0]
      if (!file || file.type !== 'text/plain') return

      // Credit: https://stackoverflow.com/a/754398/52160
      const reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      reader.onload = evt => {
        const text = evt.target.result
        const tags = text
          .split('\n')
          .filter(line => !!line)
          .map(line => GeoTag.fromRaw(line))
        if (tags.length > 0) {
          // this.log(tags)
          store.commit('tags/SET_TAGS', tags)
        }
      }
      reader.onerror = evt => {
        this.error(evt)
      }
    },
    autoRemoveWrongTags() {
      const wrongTags = []

      for (let i = 0; i < this.tags.length; i++) {
        const tag = this.tags[i]
        if (Math.abs(tag.position.lat) > 90) {
          wrongTags.push(tag)
        } else if (Math.abs(tag.position.lng) > 180) {
          wrongTags.push(tag)
        }
      }

      store.dispatch('tags/removeTags', wrongTags)
    },
    clickTagEx(tag) {
      store.dispatch('tags/editTag', { tag, key: 'disableEx', value: !tag.disableEx })
    },
    doNothing() {
      this.log('doNothing')
    },
    beginAdjustTime() {
      store.dispatch('tags/beginAdjustTime')
    },
    cancelAdjustTime() {
      store.dispatch('tags/cancelAdjustTime')
    },
    confirmAdjustTime() {
      store.dispatch('tags/confirmAdjustTime')
    },
  },
}
</script>

<style lang="scss">
#edit-tags-table {
  .b-table-selectable {
    .feather {
      font-size: 1.3rem;
    }
  }

  .table.b-table > tbody td {
    font-size: 90%;
  }

  .card-link {
    font-size: 90%;
  }

  .card-link + .card-link {
    margin-left: 12px;
  }

  .alert,
  .card.compact {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .alert-dismissible .close.yes {
    right: 30px;
    // font-size: 95%;
  }

  .table.b-table > tbody .b-table-row-selected.table-active td {
    color: #fff;
    background-color: #00acc1 !important;
  }

  .card.compact > .card-header {
    padding: 10px;
    font-size: 90%;
  }

  .card.compact > .card-header > .card-title {
    font-size: 15px;
  }
}
</style>
