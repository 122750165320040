<template>
  <div style="height: inherit">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">{{ totalPhotos }} results found</div>
            </div>
            <div class="view-options d-flex">
              <b-button
                v-if="embedded"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="$emit('gallery-new-photo')"
              >
                <!-- <feather-icon icon="StarIcon" class="mr-50" /> -->
                <span>New Photo</span>
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                :to="{ name: 'new-photo' }"
              >
                <!-- <feather-icon icon="StarIcon" class="mr-50" /> -->
                <span>New Photo</span>
              </b-button>

              <!-- Sort Button -->
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="sortBy.text"
                right
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="sortOption in sortByOptions"
                  :key="sortOption.value"
                  @click="sortBy = sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio v-for="option in itemViewOptions" :key="option.value" :value="option.value">
                  <feather-icon :icon="option.icon" size="18" />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input v-model="filters.q" placeholder="Search Product" class="search-product" />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Prodcuts -->
    <section :class="itemView">
      <b-card v-for="photo in photos" :key="photo.key" class="ecommerce-card" no-body>
        <div class="item-img text-center">
          <b-link v-if="embedded" @click="$emit('gallery-click-photo', photo)">
            <!-- <b-img :alt="`${photo.t}-${photo.key}`" fluid class="card-img-top" :src="photo.url" hidden /> -->
            <div class="photo" fluid :style="`background-image: url(${photo.url});`" />
          </b-link>
          <b-link v-else :to="{ name: 'edit-photo', params: { photoId: photo.key } }">
            <!-- <b-img :alt="`${photo.t}-${photo.key}`" fluid class="card-img-top" :src="photo.url" hidden /> -->
            <div class="photo" fluid :style="`background-image: url(${photo.url});`" />
          </b-link>
        </div>

        <!-- Product Details -->
        <b-card-body>
          <div class="item-wrapper">
            <div class="item-rating">
              <b-badge v-for="word in photo.ks" :key="word" variant="light-primary" style="margin-right: 10px">
                {{ word }}
              </b-badge>
              <!-- <ul class="unstyled-list list-inline">
                <li v-for="star in 5" :key="star" class="ratings-list-item" :class="{ 'ml-25': star - 1 }">
                  <feather-icon icon="StarIcon" size="16" />
                </li>
              </ul> -->
            </div>
            <div>
              <h6 class="item-price">
                <feather-icon v-if="photo.if" icon="HeartIcon" class="mr-50 text-danger" />
              </h6>
            </div>
          </div>
          <h6 class="item-name">
            <b-link class="text-body" :to="{ name: 'edit-photo', params: { photoId: photo.key } }">
              <!-- <feather-icon v-if="photo.if" icon="HeartIcon" class="mr-50 text-danger" /> -->
              {{ photo.t }}
            </b-link>
            <!-- <b-card-text class="item-company">
              By
              <b-link class="ml-25">
                {{ product.brand }}
              </b-link>
            </b-card-text> -->
          </h6>
          <b-card-text class="item-description">
            {{ photo.desc }}
          </b-card-text>
        </b-card-body>

        <!-- Product Actions -->
        <div v-if="embedded" class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <!-- <h4 class="item-price">${{ product.price }}</h4> -->
            </div>
          </div>
          <!-- <b-button variant="light" tag="a" class="btn-wishlist">
            <feather-icon icon="HeartIcon" class="mr-50" :class="{ 'text-danger': true }" />
            <span>Wishlist</span>
          </b-button> -->
          <b-button variant="primary" tag="a" class="btn-cart" @click="$emit('gallery-use-photo', photo.key)">
            <!-- <feather-icon icon="CheckIcon" class="mr-50" /> -->
            <span>Use Photo</span>
          </b-button>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalPhotos"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <gallery-left-filter-sidebar
        :embedded="embedded"
        :filters="filters"
        :filter-options-keywords="filterOptionsKeywords"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @gallery-close="$emit('gallery-close')"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  // BImg,
  BCardText,
  BButton,
  BPagination,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'

import mixinUI from '@/store/ui/mixin'
import mixinServer from '@/store/server/mixin'
import mixinDebug from './mixinDebug'

import GalleryLeftFilterSidebar from './ViewGalleryLeftFilterSidebar.vue'

export default {
  name: 'ViewGallery',
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    // BImg,
    BCardText,
    BButton,
    BPagination,
    BBadge,

    // SFC
    GalleryLeftFilterSidebar,
  },
  mixins: [mixinUI, mixinServer, mixinDebug],
  props: {
    embedded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      sortBy: { text: 'Favorite', value: 'favorite' },
      sortByOptions: [
        { text: 'Favorite', value: 'favorite' },
        { text: 'Newest', value: 'time-desc' },
        { text: 'Oldest', value: 'time-asc' },
      ],
      filters: {
        q: '',
        keywords: [],
        page: 1,
        perPage: 9,
      },
      itemView: 'grid-view',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      // Pagination count <= required by pagination component
      totalPhotos: null,
      photos: [],
    }
  },
  computed: {
    filterOptionsKeywords() {
      const keywords = this.userPhotoKeywords.split(',')
      keywords.unshift('all')
      return keywords
    },
  },
  watch: {
    filters: {
      handler() {
        this.fetchPhotos()
      },
      deep: true,
    },
    sortBy: {
      handler() {
        this.fetchPhotos()
      },
      deep: true,
    },
  },
  beforeMount() {
    this.fetchPhotos()
  },
  methods: {
    fetchPhotos() {
      store
        .dispatch('server/fetchPhotos', {
          q: this.filters.q,
          sortBy: this.sortBy.value,
          keywords: this.filters.keywords,
          page: this.filters.page,
          perPage: this.filters.perPage,
        })
        .then(response => {
          this.log(response)
          this.photos = response.rows
          this.totalPhotos = response.records
        })
    },
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    return {
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

[dir] .ecommerce-application .grid-view .ecommerce-card .item-img {
  padding-top: 0;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
  align-items: start;
}

.item-img {
  a {
    width: 100%;

    .photo {
      background-color: #000;
      // width: 100%;
      height: 220px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
</style>
