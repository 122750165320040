import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { log } from '@/utils'

// axios.interceptors.request.use(
//   config => config,
//   error => {
//     log(error)
//     return Promise.reject(error)
//   }
// )

// axios.interceptors.response.use(
//   response => response,
//   error => {
//     log(error.response)
//     if (error.response && error.response.data) {
//       Promise.reject(error.response.data)
//     }
//     return Promise.reject(error)
//   }
// )

const SERVER_URL = 'https://api1.geotuber.com' // 'http://192.168.1.2:8890'

const photoCache = {}

function auth() {
  const userToken = localStorage.getItem('user_token')
  if (userToken) {
    return {
      Authorization: `Bearer ${userToken}`
    }
  } else {
    return {}
  }
}

function get(url, headers, params) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      responseType: 'json',
      url: `${SERVER_URL}${url}`,
      params,
      headers
    })
      .then(res => {
        if (res.status === 200) {
          return resolve(res.data)
        } else {
          return reject()
        }
      })
      .catch(error => {
        if (error.response && error.response.data) {
          return reject(error.response.data)
        }
        return reject()
      })
  })
}

function post(url, data, headers) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}${url}`, data, { headers })
      .then(res => {
        if (res.status === 200) {
          return resolve(res.data)
        } else {
          log(res)
          return reject()
        }
      })
      .catch(error => {
        if (error.response && error.response.data) {
          return reject(error.response.data)
        }
        return reject()
      })
  })
}

const defaultPhotoKeywords = 'mountain,lake,grassland,road trip'

export default {
  namespaced: true,
  state: {
    user: false,
    photoKeywords: defaultPhotoKeywords,
    youtubeVideos: [],
    youtubeChannels: [],
    autoCompleteSessionToken: null
  },
  getters: {},
  mutations: {
    SET_USER(state, val) {
      state.user = val
      if (val) {
        state.photoKeywords = val.photo_keywords || defaultPhotoKeywords
      } else {
        state.photoKeywords = defaultPhotoKeywords
      }
    },
    SET_PHOTO_KEYWORDS(state, val) {
      state.photoKeywords = val || defaultPhotoKeywords
    }
  },
  actions: {
    login(_, redirectUrl) {
      log(redirectUrl)
      return get(`/user/oauth2?webpage=${Buffer.from(redirectUrl).toString('base64')}`).then(res => {
        log(res)
        window.location.href = res.url
      })
    },
    logout() {
      return get('/user/logout', auth()).then(() => {
        localStorage.removeItem('user_token')
      })
    },
    retrieveToken(_, tokenId) {
      return get(`/user/retrieveToken/${tokenId}`, {}).then(data => {
        localStorage.setItem('user_token', data.token)
      })
    },
    getUser({ state }) {
      return get('/user/info', auth()).then(data => {
        state.user = data
      })
    },
    postUserData(_, data) {
      return post('/user/edit', data, auth()).then(res => {
        log('saved')
        log(res)
      })
    },
    getTagsByVideoId(context, videoId) {
      return get(`/tags/video/${videoId}`, auth())
    },
    getTagsByKey(context, { key, videoId }) {
      return get(`/tags/key/${key}`, auth(), { id: videoId })
    },
    postTags(context, data) {
      return post('/tags/save', data, auth())
    },
    getPoi(_, placeKey) {
      // log(placeId)
      // log(new Error().stack)
      return get(`/poi/get/${placeKey}`, auth()).then(poi => {
        poi.location = { lat: poi.lat, lng: poi.lng }
        return poi
      })
    },
    getPoiByPlaceId({ state }, placeId) {
      const query = {}
      if (state.autoCompleteSessionToken) {
        query.sessiontoken = state.autoCompleteSessionToken
        state.autoCompleteSessionToken = null
      }
      return get(`/poi/getPlace/${placeId}`, auth(), query).then(poi => {
        poi.location = { lat: poi.lat, lng: poi.lng }
        return poi
      })
    },
    getAutoComplete({ state }, query) {
      if (!state.autoCompleteSessionToken) {
        state.autoCompleteSessionToken = uuidv4()
      }
      query.sessiontoken = state.autoCompleteSessionToken
      return get('/poi/autoComplete', auth(), query).then(({ status, predictions }) => {
        if (status === 'OK') {
          log(predictions)

          const queriedData = { places: [] }

          predictions.forEach(item => {
            queriedData.places.push({
              // eslint-disable-next-line global-require
              // img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
              name: item.structured_formatting.main_text,
              address: item.structured_formatting.secondary_text,
              types: item.types.join(', '),
              placeId: item.place_id
            })
          })
          return queriedData
        }

        return new Error()
      })
    },
    getFavoriteVideos(context, category) {
      return get(`/video/favorites?category=${category}`, auth())
    },
    getVideo(context, videoId) {
      return get(`/video/get/${videoId}`, auth())
    },
    getRelatedVideos(context, videoId) {
      return get(`/video/related/${videoId}`, auth())
    },
    getTagsOfVideo(context, videoId) {
      return get(`/tags/listOfVideo/${videoId}`, auth())
    },
    saveVideo(context, data) {
      return post('/video/save', data, auth())
    },
    editVideo(context, data) {
      return post('/video/edit', data, auth())
    },
    fetchAllVideos({ state }, payload) {
      return new Promise(resolve => {
        if (state.youtubeVideos.length > 0) {
          return resolve()
        }

        return get('/user/allVideos', auth(), payload).then(response => {
          log(response)
          const channels = {}

          state.youtubeVideos =
            response.videos.map(video => {
              video.viewCount = parseInt(video.statistics.viewCount, 10)
              video.publishedAt = Date.parse(video.snippet.publishedAt)

              // log(video)

              video.coverUrl = null
              if (video.snippet.thumbnails) {
                video.coverUrl = video.snippet.thumbnails.standard ? video.snippet.thumbnails.standard.url : null
                video.coverUrl =
                  video.coverUrl || (video.snippet.thumbnails.high ? video.snippet.thumbnails.high.url : null)
                video.coverUrl =
                  video.coverUrl || (video.snippet.thumbnails.medium ? video.snippet.thumbnails.medium.url : null)
                video.coverUrl =
                  video.coverUrl || (video.snippet.thumbnails.default ? video.snippet.thumbnails.default.url : null)
              }

              channels[video.snippet.channelId] = channels[video.snippet.channelId] || {
                id: video.snippet.channelId,
                title: video.snippet.channelTitle,
                count: 0
              }
              channels[video.snippet.channelId].count++
              return video
            }) || []

          state.youtubeChannels = Object.keys(channels)
            .map(id => channels[id])
            // eslint-disable-next-line no-nested-ternary
            .sort((a, b) => -(a.count > b.count ? 1 : a.count < b.count ? -1 : 0))
          log(state.youtubeChannels)

          return resolve()
        })
      })
    },
    fetchVideos({ state }, payload) {
      const sortCompare = key => (a, b) => {
        const fieldA = a[key]
        const fieldB = b[key]

        let comparison = 0
        if (fieldA > fieldB) {
          comparison = 1
        } else if (fieldA < fieldB) {
          comparison = -1
        }
        return comparison
      }

      const paginateArray = (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage)

      return new Promise(resolve => {
        log(payload)
        const { q = '', sortBy = 'featured', channels = 'all', perPage = 9, page = 1 } = payload

        const queryLowered = q.toLowerCase()

        let filteredData = state.youtubeVideos.filter(
          video =>
            // eslint-disable-next-line implicit-arrow-linebreak
            video.snippet.title.toLowerCase().includes(queryLowered)
          // eslint-disable-next-line function-paren-newline
        )

        if (channels && channels !== 'all') {
          filteredData = filteredData.filter(video => video.snippet.channelTitle === channels)
        }

        let sortDesc = false
        const sortByKey = (() => {
          if (sortBy === 'publishedAt-desc') {
            sortDesc = true
            return 'publishedAt'
          }
          if (sortBy === 'publishedAt-asc') {
            return 'publishedAt'
          }
          sortDesc = true
          return 'viewCount'
        })()

        const sortedData = filteredData.sort(sortCompare(sortByKey))
        if (sortDesc) sortedData.reverse()

        const paginatedData = JSON.parse(JSON.stringify(paginateArray(sortedData, perPage, page)))

        return resolve({
          videos: paginatedData,
          total: filteredData.length
        })
      })
    },
    fetchVideo({ state }, { videoId }) {
      return new Promise(resolve => {
        log(state)
        const filteredData = state.youtubeVideos.filter(video => video.id === videoId)
        resolve(filteredData.length ? filteredData[0] : null)
      })
    },
    editPhoto(context, { key, data }) {
      return post(`/media/photo/${key}`, data, auth())
    },
    savePhoto(context, data) {
      return post('/media/photo', data, auth())
    },
    getPhoto(context, photoId) {
      return new Promise((resolve, reject) => {
        if (photoCache[photoId]) {
          return resolve(photoCache[photoId])
        }
        return get(`/media/photo/${photoId}`)
          .then(photo => {
            photoCache[photoId] = photo
            return resolve(photo)
          })
          .catch(reject)
      })
    },
    // Gallery Support
    fetchPhotos(ctx, payload) {
      return get('/media/photos', auth(), payload)
    },
    fetchPhoto(ctx, { photoId }) {
      return get(`/media/photo/${photoId}`, auth())
    }
  }
}
