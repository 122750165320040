// import async from 'async'
import { getDistance } from 'geolib'
import { positionLerp, GPOS, timePerf, log } from '@/utils'
import Vue from 'vue'
import GeoTag from '@/model/geotag'
import { compressToUTF16, decompressFromUTF16 } from 'lz-string'

// eslint-disable-next-line import/no-cycle
import store from '@/store'

function localStorageKeys() {
  const userKey = store.state.server.user ? store.state.server.user.id : null
  const videoId = store.state.tags.videoId

  if (!userKey || !videoId) {
    return false
  }

  return {
    tags: `${userKey}_${videoId}_tags`,
    key: `${userKey}_${videoId}_key`,
    edited: `${userKey}_${videoId}_edited`
  }
}

function getLocalTagsKey() {
  const keys = localStorageKeys()
  if (keys) {
    return localStorage.getItem(keys.key)
  } else {
    return false
  }
}

function setLocalTagsKey(tagsKey) {
  const keys = localStorageKeys()
  if (keys) {
    log('set local tags key:', tagsKey)
    localStorage.setItem(keys.key, tagsKey)
    return true
  } else {
    return false
  }
}

function saveToLocal(tags) {
  const keys = localStorageKeys()
  if (keys) {
    const json = tags.map(tag => tag.toJSON())
    // log(json)
    localStorage.setItem(keys.tags, compressToUTF16(JSON.stringify(json)))
    localStorage.setItem(keys.edited, true)
    log('saved to local:', keys.tags, `${keys.edited} = true`)
    return true
  } else {
    return false
  }
}

function getFromLocal() {
  const keys = localStorageKeys()
  if (keys) {
    log(`try to load ${keys.tags} from localStorage`)
    return localStorage.getItem(keys.tags)
  } else {
    return false
  }
}

function getLocalEdited() {
  const keys = localStorageKeys()
  if (keys) {
    return localStorage.getItem(keys.edited)
  } else {
    return false
  }
}

function resetEdited() {
  const keys = localStorageKeys()
  if (keys) {
    return localStorage.removeItem(keys.edited)
  } else {
    return false
  }
}

// eslint-disable-next-line no-unused-vars
class HasKeyError extends Error {
  constructor(key) {
    super('add key to url')
    this.localTagsKey = key
  }
}

export default {
  namespaced: true,
  state: {
    // YouTube Video Info
    contentDetails: false,
    snippet: false,
    statistics: false,

    key: false,
    isEdited: false,

    // videlId related
    videoId: false,
    totalSeconds: 0,
    currentSeconds: 0,
    currentIndex: 0,
    poi: false,
    lockPoi: false,
    photo: false,
    lockPhoto: false,
    pov: false,
    route: false,
    location: {
      lat: 0,
      lng: 0
    },
    tags: [],
    // adjust time
    editTimeMode: false,
    workingIndex: -1,
    savedSeconds: 0
  },
  getters: {
    currentTag(state) {
      const tags = state.tags
      if (tags && state.currentIndex < tags.length) {
        return tags[state.currentIndex]
      } else {
        return false
      }
    },
    workingTag(state) {
      const tags = state.tags
      log(state.workingIndex)
      if (tags && state.workingIndex < tags.length && state.workingIndex >= 0) {
        return tags[state.workingIndex]
      } else {
        return false
      }
    },
    canEdit(state) {
      const localTagsKey = getLocalTagsKey()
      return localTagsKey !== false && (!localTagsKey || localTagsKey === state.key)
    }
  },
  mutations: {
    SET_VIDEO_ID(state, videoId) {
      log('SET_VIDEO_ID', videoId)
      state.videoId = videoId
      state.currentSeconds = 0
      state.currentIndex = 0
      state.poi = false
      state.lockPoi = false
      state.photo = false
      state.lockPhoto = false
      state.pov = false
      state.route = false
      state.editTimeMode = false
      state.workingIndex = -1
      state.savedSeconds = 0
      state.tags = []
      state.location = {
        lat: 0,
        lng: 0
      }
    },
    SET_YOUTUBE_DATA(state, data) {
      log('SET_YOUTUBE_DATA', data)
      state.snippet = data.snippet
      state.contentDetails = data.contentDetails
      state.statistics = data.statistics
      state.totalSeconds = Vue.moment.duration(data.contentDetails.duration).asSeconds()
      log('totalSeconds', state.totalSeconds)
    },
    SET_KEY(state, value) {
      log('SET_KEY', value)
      state.key = value
    },
    SET_IS_EDITED(state, value) {
      log('SET_IS_EDITED', value)
      state.isEdited = value
    },
    SET_TAGS(state, value) {
      log('SET_TAGS', value.length)
      // state.tags = value
      // Vue.set(state, 'tags', value)
      state.tags.splice(0, state.tags.length, ...value)
    },
    SET_SECONDS(state, seconds) {
      // log('SET_SECONDS', seconds)
      state.currentSeconds = seconds
    },
    SET_INDEX(state, value) {
      state.currentIndex = value
    },
    SET_WORKING_INDEX(state, value) {
      state.workingIndex = value
    },
    SET_TOTAL_SECONDS(state, seconds) {
      log('SET_TOTAL_SECONDS', seconds)
      if (seconds >= 0) {
        state.totalSeconds = seconds
        if (state.currentSeconds > state.totalSeconds) {
          state.currentSeconds = state.totalSeconds
        }
      }
    },
    SET_LOCATION(state, value) {
      // log('SET_LOCATION', value)
      state.location = value
    },
    SET_POV(state, pov) {
      state.pov = pov
    },
    SET_POI(state, poi) {
      state.poi = poi
    },
    SET_LOCK_POI(state, val) {
      state.lockPoi = val
    },
    SET_PHOTO(state, photo) {
      state.photo = photo
    },
    SET_LOCK_PHOTO(state, val) {
      state.lockPhoto = val
    },
    SET_ROUTE(state, value) {
      state.route = value
    },
    TOGGLE_EDIT_TIME_MODE(state, val) {
      state.editTimeMode = val !== undefined ? val : !state.editTimeMode
    }
  },
  actions: {
    /** Save to local storage
     * - has videoId
     * - has user login
     * - no localTagsKey, or localTagsKey === state.key
     */
    saveToStorage({ state, commit }) {
      const localTagsKey = getLocalTagsKey()
      if (localTagsKey === false) {
        // no videoId, or userId
        return
      }

      if (!localTagsKey || (state.key && localTagsKey === state.key)) {
        saveToLocal(state.tags)
        commit('SET_IS_EDITED', true)
      }
    },

    /** Reload my tags from server, update local storage
     * - need localKey
     */
    loadFromServer({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        const localKey = getLocalTagsKey()

        if (!localKey || localKey !== state.key) {
          log('can not load from server')
          return
        }

        log(`load ${state.videoId} ${localKey} from server`)

        this.dispatch('server/getTagsByKey', { key: localKey, videoId: state.videoId })
          .then(res => {
            log(res)

            let tags = res.tags
            if (res.ctags) {
              tags = JSON.parse(decompressFromUTF16(res.ctags))
            }
            tags = tags || []

            commit(
              'SET_TAGS',
              tags.map(json => new GeoTag(json))
            )
            dispatch('saveToStorage')
            resolve(res)
          })
          .catch(reject)
      })
    },
    /** Init tags data
     * - *videoId* is required
     * - *key* may in query
     * - if has *key*, get tags of *key*
     * - if no *key*, get local tags, or user's tags, or video's default tags, or no tags
     * - if user own tags, save to local tags, set local key, CAN EDIT
     * - if user does not own tags, if no local tags, save to local tags, set new local key after save, CAN EDIT
     * - if user does not own tags, if has local tags, not save to local tags, CAN NOT EDIT
     * - if no user, no local tags, no loal key, CAN NOT EDIT
     */
    // eslint-disable-next-line no-unused-vars
    setVideoIdAndKey({ state, commit, dispatch }, { videoId, key }) {
      Vue.$gtag.event('Show Video', { value: videoId })
      log('setVideoIdAndKey', videoId, key)
      return this.dispatch('server/getVideo', videoId)
        .then(res => {
          commit('SET_YOUTUBE_DATA', res.data)

          commit('SET_VIDEO_ID', videoId)
          const localKey = getLocalTagsKey()

          if (key && key !== localKey) {
            log(`get tags by ${key} from server`)
            return this.dispatch('server/getTagsByKey', { key, videoId })
          }

          // if (!key && localKey) {
          //   // throw new HasKeyError(localKey)
          //   this.$router.replace({
          //     name: 'video',
          //     params: { id: videoId },
          //     query: { key: localKey }
          //   })
          // }

          const localData = getFromLocal()
          if (localData) {
            log(`load ${videoId} (${localKey}) from localStorage`)
            const tags = JSON.parse(decompressFromUTF16(localData)).map(item => new GeoTag(item))

            // TODO: to be removed
            // async.each(
            //   tags.filter(tag => tag.placeId),
            //   (tag, callback) => {
            //     store
            //       .dispatch('server/getPoiByPlaceId', tag.placeId)
            //       .then(poi => {
            //         tag.placeKey = poi.key
            //         tag.placeId = undefined
            //         log(tag)
            //         callback()
            //       })
            //       .catch(callback)
            //   },
            //   err => {
            //     if (err) {
            //       log(err)
            //     } else {
            //       log('process done')
            //     }
            //   }
            // )

            commit('SET_TAGS', tags)
            commit('SET_KEY', localKey)
            commit('SET_IS_EDITED', getLocalEdited())
            return true
          }

          log(`load ${videoId} from server`)

          return this.dispatch('server/getTagsByVideoId', videoId).catch(err => {
            log(err)
            commit('SET_KEY')
            commit('SET_TAGS', [])
            return true
          })
        })
        .then(res => {
          if (res !== true) {
            log('--- got tags from server ---')
            log(res)

            let tags = res.tags
            if (res.ctags) {
              // log('decompress tags len:', res.ctags.length, decompressFromUTF16(res.ctags).length) // decompress tags len: 94630 1063403
              tags = JSON.parse(decompressFromUTF16(res.ctags))
            }
            tags = tags || []

            commit(
              'SET_TAGS',
              tags.map(json => new GeoTag(json))
            )
            commit('SET_KEY', res.key)

            const user = this.state.server.user
            if (user && user.id === res.uid) {
              setLocalTagsKey(res.key)
            }

            dispatch('saveToStorage')
          }

          const localKey = getLocalTagsKey()
          if (!key && localKey) {
            return localKey
          }

          return false
        })
        .finally(() => {
          if (state.tags.length > 0) {
            dispatch('setLocation', state.tags[0].position)
          }
        })
    },
    // setVideoId({ commit, dispatch }, videoId) {
    //   commit('SET_VIDEO_ID', videoId)
    //   return new Promise(resolve => {
    //     const localData = localStorage.getItem(videoId)
    //     if (localData) {
    //       log(`load ${videoId} from localStorage`)
    //       commit(
    //         'SET_TAGS',
    //         JSON.parse(decompressFromUTF16(localData)).map(item => new GeoTag(item))
    //       )
    //       commit('SET_KEY', localStorage.getItem(`${videoId}_key`))
    //       return resolve()
    //     }

    //     return dispatch('loadFromServer')
    //       .then(res => {
    //         commit('SET_KEY', res.key)
    //       })
    //       .catch(err => {
    //         log(err)
    //         commit('SET_KEY')
    //         commit('SET_TAGS', [])

    //         return resolve()
    //       })
    //   })
    // },
    setLat({ state, dispatch }, lat) {
      dispatch('setLocation', { lat, lng: state.location.lng })
    },
    setLng({ state, dispatch }, lng) {
      dispatch('setLocation', { lng, lat: state.location.lat })
    },
    /**
     * **setSeconds** UPDATE:
     * - currentSeconds
     * - currentIndex -> currentTag
     */
    setSeconds({ state, commit }, seconds) {
      // log('tags/setSeconds', seconds)
      const tags = state.tags

      let s = seconds || 0
      if (s < 0) {
        s = 0
      } else if (s > state.totalSeconds) {
        s = state.totalSeconds
      }

      if (tags && tags.length > 0) {
        let i = 0
        let e = tags.length - 1
        while (i < e - 1) {
          const m = Math.floor((i + e) / 2)
          if (tags[m].seconds > s) {
            e = m
          } else {
            i = m
          }
        }
        if (s >= tags[e].seconds) {
          i = e
        }

        commit('SET_INDEX', i)
      }

      commit('SET_SECONDS', s)
    },
    updateLocation({ state, dispatch }) {
      const tags = state.tags
      if (tags && tags.length > 0) {
        const i = state.currentIndex
        const s = state.currentSeconds

        if (i === tags.length - 1 || tags[i].still) {
          dispatch('setLocation', tags[i].position)
        } else {
          const r = Math.max(Math.min((s - tags[i].seconds) / (tags[i + 1].seconds - tags[i].seconds), 1), 0)
          dispatch('setLocation', positionLerp(tags[i].position, tags[i + 1].position, r))
        }
      }
    },
    setLocation({ commit }, location) {
      // log('tags/setLocation', location)
      commit('SET_LOCATION', location)
    },
    saveTag({ state, commit, dispatch }) {
      const s = Math.floor(state.currentSeconds)

      const tag = new GeoTag({
        seconds: s,
        position: state.location
      })

      if (state.pov) {
        tag.pov = state.pov
      } else if (state.poi) {
        tag.placeKey = state.poi.key
      } else if (state.photo) {
        tag.photoId = state.photo.key
      }

      const tags = state.tags

      let i = 0
      while (i < tags.length) {
        if (s < tags[i].seconds) {
          break
        }
        i++
      }

      if (i === 0) {
        tags.unshift(tag)
        commit('SET_INDEX', 0)
      } else if (s === tags[i - 1].seconds) {
        if (!tags[i - 1].lock) {
          // tags[i - 1] = tag
          // Vue.set(tags, i - 1, tag)
          tags[i - 1].copy(tag)
          commit('SET_INDEX', i - 1)
        }
      } else {
        tags.splice(i, 0, tag)
        commit('SET_INDEX', i)
      }

      // commit('SET_TAGS', tags)

      dispatch('saveToStorage')
    },
    // eslint-disable-next-line no-unused-vars
    removeTags({ state, commit, dispatch }, tags) {
      const stateTags = state.tags

      tags.forEach(tag => {
        if (!tag.lock) {
          const i = stateTags.indexOf(tag)
          if (i > -1) {
            stateTags.splice(i, 1)
          }
        }
      })

      // commit('SET_TAGS', stateTags)

      dispatch('saveToStorage')
    },
    removeAllTags({ state, commit, dispatch }) {
      const tags = state.tags.filter(tag => tag.lock)

      commit('SET_TAGS', tags)

      dispatch('saveToStorage')
    },
    addRouteTags({ state, commit, dispatch }) {
      if (state.route) {
        const tags = state.tags

        state.route.forEach(seg => {
          let hasLockedTag = false

          let f = 0
          for (; f < tags.length; f++) {
            if (tags[f].seconds >= seg.from) {
              break
            }
          }

          let t = f + 1
          for (; t < tags.length; t++) {
            if (tags[t].seconds >= seg.to) {
              break
            }
            if (tags[t].lock) {
              hasLockedTag = true
              break
            }
          }

          if (!hasLockedTag) {
            tags.splice(f + 1, t - f - 1, ...seg.tags)
          } else {
            // TODO: show something to user
          }
        })

        // commit('SET_TAGS', tags)
        commit('SET_ROUTE', false)

        dispatch('saveToStorage')
      }
    },
    editTag({ dispatch }, { tag, key, value }) {
      Vue.set(tag, key, value)
      dispatch('saveToStorage')
    },
    beginAdjustTime({ getters, state }) {
      const tag = getters.currentTag
      if (!state.editTimeMode && tag && tag.lock) {
        state.workingIndex = state.currentIndex
        state.savedSeconds = tag.seconds
        state.editTimeMode = true
      }
    },
    cancelAdjustTime({ getters, state }) {
      if (state.editTimeMode) {
        const tag = getters.workingTag
        if (tag) {
          tag.seconds = state.savedSeconds
        }

        state.workingIndex = -1
        state.savedSeconds = 0
        state.editTimeMode = false
      }
    },
    confirmAdjustTime({ getters, state, dispatch }) {
      if (state.editTimeMode) {
        const tag = getters.workingTag
        if (tag) {
          /* && tag.seconds !== state.savedSeconds */
          const tags = state.tags
          const index = state.workingIndex

          // eslint-disable-next-line no-inner-declarations
          function computeTag(begin, end) {
            if (begin > end - 2) {
              return null
            }
            if (tags[begin].seconds >= tags[end].seconds) {
              return null
            }
            const steps = []
            let distance = 0
            for (let i = 0; i < end - begin - 1; i++) {
              distance += getDistance(GPOS(tags[begin + i].position), GPOS(tags[begin + i + 1].position))
              steps.push({
                distance
              })
            }

            distance += getDistance(GPOS(tags[end].position), GPOS(tags[end - 1].position))
            const seconds = tags[end].seconds - tags[begin].seconds
            for (let i = 0; i < end - begin - 1; i++) {
              steps[i].seconds = Math.floor((seconds * steps[i].distance) / distance)
              if (i === 0) {
                steps[i].ds = steps[i].seconds
              } else {
                steps[i].ds = steps[i].seconds - steps[i - 1].seconds
              }
            }

            // log(steps)

            const adjusted = []
            for (let i = 0; i < end - begin - 1; i++) {
              if (steps[i].ds > 0) {
                const t = tags[begin + i + 1]
                t.seconds = tags[begin].seconds + steps[i].seconds
                adjusted.push(t)
              }
            }
            return adjusted
          }

          // do forward first
          let indexF = index + 1
          while (indexF < tags.length - 1 && !tags[indexF].lock) indexF++
          const adjustedF = computeTag(index, indexF)
          if (adjustedF) {
            state.tags.splice(index + 1, indexF - index - 1, ...adjustedF)
          }

          // do backward second
          let indexB = index - 1
          while (indexB > 0 && !tags[indexB].lock) indexB--
          const adjustedB = computeTag(indexB, index)
          if (adjustedB) {
            state.tags.splice(indexB + 1, index - indexB - 1, ...adjustedB)
          }

          // log(index, indexF, indexB)
          dispatch('saveToStorage')
        }

        state.workingIndex = -1
        state.savedSeconds = 0
        state.editTimeMode = false
      }
    },
    upload({ state, commit }) {
      return new Promise((resolve, reject) => {
        const videoId = state.videoId
        const tagsKey = getLocalTagsKey()

        if (videoId && (!tagsKey || !state.key || tagsKey === state.key)) {
          const tags = state.tags
          const json = tags.map(tag => tag.toJSON())
          const data = {
            vid: videoId,
            // tags: json,
            ctags: compressToUTF16(JSON.stringify(json)),
            tc: tags.length,
            ti: state.totalSeconds
          }
          if (tags.length > 0) {
            data.f_lat = tags[0].position.lat
            data.f_lng = tags[0].position.lng
            data.t_lat = tags[tags.length - 1].position.lat
            data.t_lng = tags[tags.length - 1].position.lng

            const route = []
            const p = 100000
            route.push(`${Math.floor(data.f_lat * p) / p},${Math.floor(data.f_lng * p) / p}`)
            let d = 0
            for (let i = 1; i < tags.length - 1; i++) {
              d += getDistance(GPOS(tags[i].position), GPOS(tags[i - 1].position))
              if (d >= 10000) {
                route.push(`${Math.floor(tags[i].position.lat * p) / p},${Math.floor(tags[i].position.lng * p) / p}`)
                d = 0
              }
            }
            route.push(`${Math.floor(data.t_lat * p) / p},${Math.floor(data.t_lng * p) / p}`)
            const routeStr = route.join(';')
            const compressedStr = timePerf(() => compressToUTF16(routeStr))
            data.r = compressedStr
          }

          if (tagsKey) {
            data.key = tagsKey
          }

          if (state.snippet) {
            log(state.snippet)
            data.t = state.snippet.title
            data.desc = state.snippet.description
            data.cid = state.snippet.channelId
            data.ct = state.snippet.channelTitle
            data.kw = state.snippet.tags
            if (state.snippet.thumbnails) {
              if (state.snippet.thumbnails.standard) {
                data.img = state.snippet.thumbnails.standard.url
              } else if (state.snippet.thumbnails.medium) {
                data.img = state.snippet.thumbnails.medium.url
              } else if (state.snippet.thumbnails.default) {
                data.img = state.snippet.thumbnails.default.url
              } else if (state.snippet.thumbnails.high) {
                data.img = state.snippet.thumbnails.high.url
              } else if (state.snippet.thumbnails.maxres) {
                data.img = state.snippet.thumbnails.maxres.url
              }
            }
          }

          // log(data)

          this.dispatch('server/postTags', data)
            .then(res => {
              log(res)
              if (res.key) {
                commit('SET_KEY', res.key)
                setLocalTagsKey(res.key)
              }

              resetEdited()
              commit('SET_IS_EDITED', false)

              resolve()
            })
            .catch(e => {
              log(e)
              reject(e)
            })
        } else {
          reject(new Error('Nothing to save'))
        }
      })
    }
  }
}
