import { log } from '@/utils'

export default {
  namespaced: true,
  state: {
    useGoogleMap: localStorage.getItem('google-map') === 'true',

    // Navibar
    showNavibarSearchPoi: false,
    showNavibarSearchVideo: false,
    showNavibarLayout: false,
    showNavibarDarkMode: true,
    showNavibarEditTags: false,

    // Sidebar Right
    showSidebarRightEditTags: false,

    // VideoMap
    playVideo: false,
    showStreetView: false,
    showDismissSeconds: 0,
    lockStreetView: false,
    mapCenter: { lat: 10, lng: 10 },

    // VideoIndex
    mapCenterVideoIndex: { lat: 10, lng: 10 },
    zoomVideoIndex: 12,

    // Overlay
    showingPoi: false,
    showingPhoto: false,

    // ViewYouTubeLeftFilter
    filtersYouTube: {
      q: '',
      channels: 'all',
      page: 1,
      perPage: 6
    },
    sortByYouTube: { text: 'Views', value: 'viewCount' },
    itemViewYouTube: 'grid-view'
  },
  getters: {},
  mutations: {
    TOGGLE_GOOGLE_MAP(state, val) {
      state.useGoogleMap = val !== undefined ? val : !state.useGoogleMap
      localStorage.setItem('google-map', val)
    },
    TOGGLE_SIDEBAR_RIGHT_EDIT_TAGS(state, val) {
      state.showSidebarRightEditTags = val !== undefined ? val : !state.showSidebarRightEditTags
    },
    TOGGLE_NAVIBAR_SEARCH_POI(state, val) {
      state.showNavibarSearchPoi = val !== undefined ? val : !state.showNavibarSearchPoi
    },
    TOGGLE_NAVIBAR_SEARCH_VIDEO(state, val) {
      state.showNavibarSearchVideo = val !== undefined ? val : !state.showNavibarSearchVideo
    },
    TOGGLE_NAVIBAR_LAYOUT(state, val) {
      state.showNavibarLayout = val !== undefined ? val : !state.showNavibarLayout
    },
    TOGGLE_NAVIBAR_DARK_MODE(state, val) {
      state.showNavibarDarkMode = val !== undefined ? val : !state.showNavibarDarkMode
    },
    TOGGLE_NAVIBAR_EDIT_TAGS(state, val) {
      state.showNavibarEditTags = val !== undefined ? val : !state.showNavibarEditTags
    },
    TOGGLE_STREET_VIEW(state, val) {
      state.showStreetView = val !== undefined ? val : !state.showStreetView
    },
    TOGGLE_LOCK_STREET_VIEW(state, val) {
      state.lockStreetView = val !== undefined ? val : !state.lockStreetView
    },
    TOGGLE_PLAY_VIDEO(state, val) {
      state.playVideo = val !== undefined ? val : !state.playVideo
    },
    SET_SHOWING_POI(state, val) {
      state.showingPoi = val
    },
    SET_SHOWING_PHOTO(state, val) {
      state.showingPhoto = val
    },
    SET_MAP_CENTER(state, val) {
      state.mapCenter = val
    },
    SET_SHOW_DISMISS_SECONDS(state, val) {
      log('SET_SHOW_DISMISS_SECONDS', val)
      state.showDismissSeconds = val
    }
  },
  actions: {
    showPoi({ commit, dispatch }, poi) {
      dispatch('hideShowingOverlay')
      commit('SET_SHOWING_POI', poi)
    },
    showPhoto({ commit, dispatch }, photoId) {
      dispatch('hideShowingOverlay')

      this.dispatch('server/getPhoto', photoId).then(photo => {
        commit('SET_SHOWING_PHOTO', photo)
      })
    },
    hideShowingOverlay({ commit }) {
      commit('SET_SHOWING_POI', false)
      commit('SET_SHOWING_PHOTO', false)
    },
    reset({ dispatch }) {
      dispatch('hideShowingOverlay')
    }
  }
}
