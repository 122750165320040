<template>
  <b-button
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    variant="outline-primary"
    class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
    :to="{ name: 'new-photo' }"
  >
    <!-- <feather-icon icon="StarIcon" class="mr-50" /> -->
    <span>New Photo</span>
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
  },
}
</script>
