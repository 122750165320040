import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import VueYoutube from 'vue-youtube'
import * as VueGoogleMaps from 'vue2-google-maps'
import firebase from 'firebase/app'

import VueGtag from 'vue-gtag'

// import axios from 'axios'
// import VueAxios from 'vue-axios'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Youtube
Vue.use(VueYoutube)

// Google Maps
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC8TTmuJmL7FJAzfMntX1CsjvWZCdrQ5_E'
    // libraries: 'places' // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    // If you want to set the version, you can do so:
    // v: '3.26',
  },

  // If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  autobindAllEvents: false,

  // If you want to manually install components, e.g.
  // import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then disable the following:
  installComponents: true
})

// Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyBy2-wWNTqobKYctb_6ZIPyY_PSZgvToXg',
  authDomain: 'geotuber.firebaseapp.com',
  projectId: 'geotuber',
  storageBucket: 'geotuber.appspot.com',
  messagingSenderId: '423844443507',
  appId: '1:423844443507:web:ca18466db81c73d6b74106',
  measurementId: 'G-7W17L7H3QX'
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
// firebase.analytics()

// Vue.use(VueAxios, axios)

// My Addon Functions
const AddonFunctions = {
  install() {}
}
Vue.use(AddonFunctions)

Vue.use(require('vue-moment'))

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.use(
  VueGtag,
  {
    config: {
      id: 'G-SMDW815L3W',
      params: {
        send_page_view: false
      }
    },
    appName: 'GeoTuber',
    pageTrackerScreenviewEnabled: true
  },
  router
)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
