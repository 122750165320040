<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div class="sidebar-shop" :class="{ show: mqShallShowLeftSidebar }">
        <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block">Filters</h6>
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <b-card>
          <!-- Multi Range -->
          <!-- <div class="multi-range-price">
            <h6 class="filter-title mt-0">Multi Range</h6>
            <b-form-radio-group
              v-model="filters.priceRangeDefined"
              class="price-range-defined-radio-group"
              stacked
              :options="filterOptions.priceRangeDefined"
            />
          </div> -->

          <!-- Price Slider -->
          <!-- <div class="price-slider">
            <h6 class="filter-title">Price Range</h6>
            <vue-slider v-model="filters.priceRange" :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
          </div> -->

          <!-- Keywords -->
          <div class="product-categories">
            <h6 class="filter-title">Keywords</h6>
            <b-form-radio-group
              v-model="filters.keywords"
              class="categories-radio-group"
              stacked
              :options="filterOptionsKeywords"
            />
          </div>

          <!-- Brands -->
          <!-- <div class="brands">
            <h6 class="filter-title">Brands</h6>
            <b-form-radio-group
              v-model="filters.brands"
              class="brands-radio-group"
              stacked
              :options="filterOptions.brands"
            />
          </div> -->

          <!-- Ratings -->
          <!-- <div class="ratings">
            <h6 class="filter-title">Ratings</h6>
            <div v-for="rating in filterOptions.ratings" :key="rating.rating" class="ratings-list">
              <b-link>
                <div class="d-flex">
                  <feather-icon
                    v-for="star in 5"
                    :key="star"
                    icon="StarIcon"
                    size="17"
                    :class="[
                      { 'fill-current': star <= rating.rating },
                      star <= rating.rating ? 'text-warning' : 'text-muted',
                    ]"
                  />
                  <span class="ml-25">&amp; up</span>
                </div>
              </b-link>
              <div class="stars-received">
                <span>{{ rating.count }}</span>
              </div>
            </div>
          </div> -->
        </b-card>

        <b-button
          v-if="embedded"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          block
          variant="outline-primary"
          class="btn-cart mr-0 ml-0"
          @click="$emit('gallery-close')"
        >
          <!-- <feather-icon icon="HeartIcon" class="mr-50" :class="{ 'text-danger': isFavorite }" /> -->
          <span>Close Gallery</span>
        </b-button>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import { BRow, BCol, BCard, BFormRadioGroup, BButton } from 'bootstrap-vue'
// import VueSlider from 'vue-slider-component'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BFormRadioGroup,
    // BLink,
    BCard,
    BButton,

    // 3rd Party
    // VueSlider,
  },
  props: {
    embedded: {
      type: Boolean,
      required: false,
      default: false,
    },
    filters: {
      type: Object,
      required: true,
    },
    filterOptionsKeywords: {
      type: Array,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}

[dir] .ecommerce-application .sidebar-shop .filter-title {
  margin-top: 0;
}
</style>
