<template>
  <div class="index-map">
    <google-map
      v-if="useGoogleMap && isSuperUser"
      :center="indexMapCenter"
      :zoom="indexMapZoom"
      @map-inited="onMapInited"
      @click-video="clickVideo"
      @show-all-markers="showAllMarkers"
      @change-map-center="changeMapCenter"
      @change-zoom="changeMapZoom"
      @focus-index="val => (focusIndex = val)"
    />

    <leaflet-map
      v-else
      :center="indexMapCenter"
      :zoom="indexMapZoom"
      @map-inited="onMapInited"
      @click-video="clickVideo"
      @show-all-markers="showAllMarkers"
      @change-map-center="changeMapCenter"
      @change-zoom="changeMapZoom"
      @focus-index="val => (focusIndex = val)"
    />

    <div v-if="focusIndex >= 0" class="video-overlay ecommerce-application">
      <section class="list-view">
        <b-card class="ecommerce-card" no-body>
          <div class="item-img text-center">
            <b-link :to="{ name: 'video', params: { id: video.data.id } }">
              <b-aspect aspect="16:9">
                <div class="video" fluid :style="`background-image: url(${video.coverUrl});`" />
              </b-aspect>
            </b-link>
          </div>

          <!-- Product Details -->
          <b-card-body>
            <div class="item-wrapper">
              <div class="item-rating">{{ video.data.statistics.viewCount }} views</div>
              <div>
                <h6 class="item-price">
                  {{ video.data.contentDetails.duration | duration('humanize') }}
                </h6>
              </div>
            </div>
            <h6 class="item-name">
              <b-link class="text-body" :to="{ name: 'video', params: { id: video.data.id } }">
                <!-- <feather-icon v-if="photo.if" icon="HeartIcon" class="mr-50 text-danger" /> -->
                {{ video.data.snippet.title }}
              </b-link>
              <b-card-text class="item-company">
                By
                <b-link
                  :href="'https://www.youtube.com/channel/' + video.data.snippet.channelId"
                  target="_blank"
                  class="ml-25"
                >
                  {{ video.data.snippet.channelTitle }}
                </b-link>
              </b-card-text>
            </h6>
            <b-card-text class="item-description">
              {{ video.data.snippet.description }}
            </b-card-text>
          </b-card-body>
          <div class="item-options text-center">
            <div class="item-wrapper">
              <div class="item-cost">
                <h4 class="item-price">{{ video.data.contentDetails.duration | duration('humanize') }}</h4>
              </div>
            </div>
          </div>
        </b-card>
      </section>
    </div>
  </div>
</template>

<script>
import mixinUi from '@/store/ui/mixin'
import mixinMap from '@/store/map/mixin'
import mixinServer from '@/store/server/mixin'
import store from '@/store'
// import { timePerf } from '@/utils'
import { decompressFromUTF16 } from 'lz-string'
import { BCardText, BLink, BCard, BAspect, BCardBody } from 'bootstrap-vue'

import GoogleMap from './parts/GoogleMap.vue'
import LeafletMap from './parts/LeafletMap.vue'

import mixinDebug from './mixinDebug'

const continentData = {
  World: {
    center: { lat: 21.980617290617428, lng: 8.133479032778848 },
    zoom: 2.5,
    bounds: {
      sw: { lat: -63.31268278043484, lng: -149.41406250000003 },
      ne: { lat: 77.69287033641928, lng: 165.76171875000003 },
    },
  },
  Africa: {
    center: { lat: 1.4665675224955546, lng: 15.333648499932284 },
    zoom: 4.2,
    bounds: {
      sw: { lat: -45.398449976304086, lng: -63.45703125000001 },
      ne: { lat: 47.39834920035926, lng: 94.13085937500001 },
    },
  },
  Asia: {
    center: { lat: 33.679979023916175, lng: 98.43798248934571 },
    zoom: 4.0,
    bounds: {
      sw: { lat: -16.55196172197251, lng: 19.599609375000004 },
      ne: { lat: 65.80277639340238, lng: 177.18750000000003 },
    },
  },
  Australia: {
    center: { lat: -30.247662727110622, lng: 145.338799348807 },
    zoom: 4.9,
    bounds: {
      sw: { lat: -50.0641917366591, lng: 105.9521484375 },
      ne: { lat: -5.484768018141262, lng: 184.74609375 },
    },
  },
  Europe: {
    center: { lat: 57.411471555747255, lng: 20.46744898467727 },
    zoom: 4.5,
    bounds: {
      sw: { lat: 40.25002840251183, lng: -18.940429687500004 },
      ne: { lat: 69.05285807666016, lng: 59.85351562500001 },
    },
  },
  'North America': {
    center: { lat: 48.18913635947051, lng: -105.08568753839818 },
    zoom: 4,
    bounds: {
      sw: { lat: 2.6357885741666065, lng: -183.8671875 },
      ne: { lat: 72.63337363853837, lng: -26.279296875000004 },
    },
  },
  'South America': {
    center: { lat: -26.478966703591556, lng: -64.04076566339818 },
    zoom: 4.2,
    bounds: {
      sw: { lat: -62.18601385719424, lng: -142.91015625000003 },
      ne: { lat: 24.28702686537645, lng: 14.677734375000002 },
    },
  },
}

export default {
  name: 'ViewIndexMap',
  components: {
    BCardText,
    BLink,
    BCard,
    BCardBody,
    BAspect,
    GoogleMap,
    LeafletMap,
  },
  mixins: [mixinUi, mixinMap, mixinServer, mixinDebug],
  props: {
    continent: {
      type: String,
      required: true,
      default: 'World',
    },
  },
  data() {
    return {
      indexMapCenter: null,
      indexMapZoom: null,
      videos: [],
      focusIndex: -1,
      mapInited: false,
    }
  },
  computed: {
    video() {
      return this.focusIndex >= 0 ? this.videos[this.focusIndex] : {}
    },
  },
  watch: {
    continent() {
      this.log('continent changed:', this.continent)
      if (this.mapInited) {
        this.initContinent()
      }
    },
    videos() {
      this.onVideos()
    },
  },
  beforeMount() {
    this.showSidebarRight = false
    this.showNavibarSearchPoi = false
    this.showNavibarSearchVideo = true
    this.showNavibarLayout = false
    this.showNavibarEditTags = false

    this.indexMapCenter = store.state.ui.mapCenterVideoIndex
    this.indexMapZoom = store.state.ui.zoomVideoIndex

    store.dispatch('map/reset')

    this.mapButtons = [
      {
        icon: 'MaximizeIcon',
        event: 'show-all-markers',
        tooltip: 'show all',
      },
    ]
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    onMapInited() {
      this.mapInited = true
      this.initContinent()
    },
    onVideos() {
      this.videoMarkers = this.videos.map(video => ({
        position: {
          lat: video.tags.f_lat,
          lng: video.tags.f_lng,
        },
      }))

      this.mapPathVideos = this.videos.map(video => {
        // this.log(video.r)
        if (video.tags.r) {
          const routeStr = decompressFromUTF16(video.tags.r)
          // this.log(routeStr)
          if (routeStr) {
            const path = routeStr.split(';').map(loc => {
              const latLng = loc.split(',').map(parseFloat)
              return { lat: latLng[0], lng: latLng[1] }
            })
            // this.log(path)
            return path
          }
        }

        return []
      })
    },
    initContinent() {
      const cfg = continentData[this.continent]
      if (cfg && this.mapInited) {
        store.dispatch('map/setView', cfg)
      }

      this.$gtag.event('Show Continent', { value: this.continent })

      store
        .dispatch('server/getFavoriteVideos', this.continent)
        .then(res => {
          this.log(res)
          this.videos = res.rows
        })
        .catch(err => {
          this.log(err)
        })
    },
    clickVideo(index) {
      const video = this.videos[index]
      this.$router.push({ name: 'video', params: { id: video.key } })
    },
    showAllMarkers() {
      store.dispatch(
        'map/fitBounds',
        this.videoMarkers.map(m => m.position)
      )
    },
    changeMapCenter(val) {
      // this.log('lat:', val.lat(), ', lng:', val.lng())
      store.state.ui.mapCenterVideoIndex = val
    },
    changeMapZoom(val) {
      // this.log(val)
      store.state.ui.zoomVideoIndex = val
    },
  },
}
</script>

<style lang="scss">
.index-map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .video-overlay {
    // background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 20px;
    bottom: 0;
    right: 20px;

    .item-img {
      a {
        width: 100%;

        .video {
          background-color: #000;
          width: 100%;
          height: 100%;
          // height: 280px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }
}
</style>
