import { log } from '@/utils'

export default {
  beforeCreate() {
    log(`--- ${this.$options.name} : beforeCreate`)
  },
  created() {
    log(`--- ${this.$options.name} : created`)
  },
  beforeMount() {
    log(`--- ${this.$options.name} : beforeMount`)
  },
  mounted() {
    log(`--- ${this.$options.name} : mounted`)
  },
  beforeUpdate() {
    log(`--- ${this.$options.name} : beforeUpdate`)
  },
  updated() {
    log(`--- ${this.$options.name} : updated`)
  },
  activated() {
    log(`--- ${this.$options.name} : activated`)
  },
  deactivated() {
    log(`--- ${this.$options.name} : deactivated`)
  },
  beforeUnmount() {
    log(`--- ${this.$options.name} : beforeUnmount`)
  },
  unmounted() {
    log(`--- ${this.$options.name} : unmounted`)
  },
  errorCaptured() {
    log(`--- ${this.$options.name} : errorCaptured`)
  },
  renderTracked() {
    log(`--- ${this.$options.name} : renderTracked`)
  },
  renderTriggered() {
    log(`--- ${this.$options.name} : renderTriggered`)
  }
}
