<template>
  <div class="custom-map-button">
    <slot />
  </div>
</template>

<script>
// import store from '@/store'

import { gmapApi } from 'vue2-google-maps'

export default {
  components: {},
  props: {},
  data() {
    return {}
  },
  inject: {
    $mapPromise: { default: 'abcdef' },
  },
  computed: {},
  watch: {},
  mounted() {
    this.$mapPromise.then(map => {
      this.map = map
      map.controls[gmapApi().maps.ControlPosition.TOP_RIGHT].push(this.$el)
    })
  },
  methods: {},
}
</script>

<style lang="scss">
.custom-map-button {
  // border: 0px;
  margin-top: 10px;
  margin-right: 10px;
  // padding: 0px;
  // text-transform: none;
  // appearance: none;
  // position: absolute;
  // cursor: pointer;
  // user-select: none;
  // border-radius: 2px;
  // height: 40px;
  // width: 40px;
  // box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  // overflow: hidden;
  // top: 19px;
  // right: 0px;
}
</style>
