export const convertDMSToDD = (degrees, minutes, seconds, direction) => {
  let dd = Number(degrees) + Number(minutes) / 60 + Number(seconds) / (60 * 60)

  if (direction === 'S' || direction === 'W') {
    dd = dd * -1
  } // Don't do anything for N or E
  return dd
}

export const parseDMS = dms => {
  const parts = dms.split(/[^\d\w.]+/)
  const lat = convertDMSToDD(parts[0], parts[1], parts[2], parts[3])
  const lng = convertDMSToDD(parts[4], parts[5], parts[6], parts[7])
  return { lat, lng }
}

export const padZero = (num, size) => {
  let s = String(num)
  while (s.length < (size || 2)) {
    s = `0${s}`
  }
  return s
}

export const toDegreesMinutesAndSeconds = coordinate => {
  const absolute = Math.abs(coordinate)
  const degrees = Math.floor(absolute)
  const minutesNotTruncated = (absolute - degrees) * 60
  const minutes = Math.floor(minutesNotTruncated)
  const seconds = (minutesNotTruncated - minutes) * 60

  return `${padZero(degrees)}°${padZero(minutes)}'${padZero(seconds.toFixed(1), 4)}"`
}

export const convertLat = lat => {
  const latitude = toDegreesMinutesAndSeconds(lat)
  const latitudeCardinal = lat >= 0 ? 'N' : 'S'

  return `${latitude}${latitudeCardinal}`
}

export const convertLng = lng => {
  const longitude = toDegreesMinutesAndSeconds(lng)
  const longitudeCardinal = lng >= 0 ? 'E' : 'W'

  return `${longitude}${longitudeCardinal}`
}

export const fmtTime = seconds => {
  // new Date(seconds * 1000).toISOString().substr(11, 8)
  let s = seconds

  let h = Math.floor(s / 3600)
  s -= h * 3600

  let m = Math.floor(s / 60)
  s -= m * 60

  if (h < 10) h = `0${h}`
  if (m < 10) m = `0${m}`
  if (s < 10) s = `0${s}`

  return `${h}:${m}:${s}`
}

export const parseTime = time => {
  const parts = time.split(':').map(p => parseInt(p, 10))
  return parts[0] * 3600 + parts[1] * 60 + parts[2]
}

export const positionLerp = (p1, p2, r) => {
  const lat = p1.lat + r * (p2.lat - p1.lat)
  const lng = p1.lng + r * (p2.lng - p1.lng)
  return { lat, lng }
}

export const isFunction = functionToCheck =>
  functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'

export const GPOS = p => {
  const latitude = isFunction(p.lat) ? p.lat() : p.lat
  const longitude = isFunction(p.lng) ? p.lng() : p.lng
  return { latitude, longitude }
}

// eslint-disable-next-line no-unused-vars
export const log = (...args) => {
  // console.log(...args)
}

export const timePerf = (code, prompt) => {
  // const t1 = new Date().getTime()
  const r = code()
  // const t2 = new Date().getTime()
  if (prompt) {
    // exports.log(`${prompt} : time = ${t2 - t1} ms`)
  } else {
    // exports.log(`time = ${t2 - t1} ms`)
  }
  return r
}

export default { convertDMSToDD, parseDMS }
