import store from '@/store'
import { log } from '@/utils'

export default {
  computed: {
    mapPathLines: {
      get() {
        return store.state.map.mapPathLines
      },
      set(val) {
        log('map/SET_PATH_LINES', val)
        store.commit('map/SET_PATH_LINES', val)
      }
    },
    mapPathDots: {
      get() {
        return store.state.map.mapPathDots
      },
      set(val) {
        store.commit('map/SET_PATH_DOTS', val)
      }
    },
    mapPathVideos: {
      get() {
        return store.state.map.mapPathVideos
      },
      set(val) {
        store.commit('map/SET_PATH_VIDEOS', val)
      }
    },
    theMarker: {
      get() {
        return store.state.map.theMarker
      },
      set(val) {
        store.commit('map/SET_THE_MARKER', val)
      }
    },
    poiMarkers: {
      get() {
        return store.state.map.poiMarkers
      },
      set(val) {
        store.commit('map/SET_POI_MARKERS', val)
      }
    },
    povMarkers: {
      get() {
        return store.state.map.povMarkers
      },
      set(val) {
        store.commit('map/SET_POV_MARKERS', val)
      }
    },
    photoMarkers: {
      get() {
        return store.state.map.photoMarkers
      },
      set(val) {
        store.commit('map/SET_PHOTO_MARKERS', val)
      }
    },
    selectedMarkers: {
      get() {
        return store.state.map.selectedMarkers
      },
      set(val) {
        store.commit('map/SET_SELECTED_MARKERS', val)
      }
    },
    otherMarkers: {
      get() {
        return store.state.map.otherMarkers
      },
      set(val) {
        store.commit('map/SET_OTHER_MARKERS', val)
      }
    },
    videoMarkers: {
      get() {
        return store.state.map.videoMarkers
      },
      set(val) {
        store.commit('map/SET_VIDEO_MARKERS', val)
      }
    },
    mapButtons: {
      get() {
        return store.state.map.mapButtons
      },
      set(val) {
        store.commit('map/SET_MAP_BUTTONS', val)
      }
    }
  }
}
