<template>
  <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" right shadow no-header no-close-on-esc :visible="isEditing">
    <!-- Header -->
    <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
      <h5 class="mb-0">Edit Tags</h5>
      <div>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="$emit('hide')" />
      </div>
    </div>

    <div v-if="showSidebarRightEditTags" class="edit-container content-sidebar">
      <!-- :save="saveTag" :toggle-video-play="toggleVideoPlay" :set-time="setTime" -->
      <sidebar-edit-marker v-on="$listeners" />
      <sidebar-edit-tags v-on="$listeners" />
      <b-button block variant="outline-warning" :disabled="playVideo || !isEdited" @click="upload">Upload</b-button>
    </div>
  </b-sidebar>
</template>

<script>
import mixinUi from '@/store/ui/mixin'
import mixinTags from '@/store/tags/mixin'
import store from '@/store'
import { BSidebar, BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// for better performance, split complex view to small components
import SidebarEditMarker from './SidebarEditMarker.vue'
import SidebarEditTags from './SidebarEditTagsBTable.vue'

export default {
  components: {
    BSidebar,
    BButton,
    SidebarEditMarker,
    SidebarEditTags,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  mixins: [mixinUi, mixinTags],
  props: {
    isEditing: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    upload() {
      store
        .dispatch('tags/upload')
        .then(() => {
          this.toastS('Upload successfully', 'bottom-right')
        })
        .catch(e => {
          this.toastE(e, 'bottom-right')
        })
    },
  },
}
</script>

<style lang="scss">
.edit-container {
  padding: 20px 20px;
}
</style>
