import store from '@/store'
import { log } from '@/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  computed: {
    useGoogleMap: {
      get() {
        return store.state.ui.useGoogleMap
      },
      set(val) {
        store.commit('ui/TOGGLE_GOOGLE_MAP', val)
      }
    },
    showSidebarRight: {
      get() {
        return this.showSidebarRightEditTags
      },
      set(val) {
        if (!val) {
          this.showSidebarRightEditTags = false
        }
      }
    },
    showSidebarRightEditTags: {
      get() {
        return store.state.ui.showSidebarRightEditTags
      },
      set(val) {
        store.commit('ui/TOGGLE_SIDEBAR_RIGHT_EDIT_TAGS', val)
        if (val) {
          this.$gtag.event('Open Edit Tags')
        }
      }
    },
    showNavibarSearchPoi: {
      get() {
        return store.state.ui.showNavibarSearchPoi
      },
      set(val) {
        store.commit('ui/TOGGLE_NAVIBAR_SEARCH_POI', val)
      }
    },
    showNavibarSearchVideo: {
      get() {
        return store.state.ui.showNavibarSearchVideo
      },
      set(val) {
        store.commit('ui/TOGGLE_NAVIBAR_SEARCH_VIDEO', val)
      }
    },
    showNavibarLayout: {
      get() {
        return store.state.ui.showNavibarLayout
      },
      set(val) {
        store.commit('ui/TOGGLE_NAVIBAR_LAYOUT', val)
      }
    },
    showNavibarDarkMode: {
      get() {
        return store.state.ui.showNavibarDarkMode
      },
      set(val) {
        store.commit('ui/TOGGLE_NAVIBAR_DARK_MODE', val)
      }
    },
    showNavibarEditTags: {
      get() {
        return store.state.ui.showNavibarEditTags
      },
      set(val) {
        store.commit('ui/TOGGLE_NAVIBAR_EDIT_TAGS', val)
      }
    },
    showingPoi: {
      get() {
        return store.state.ui.showingPoi
      },
      set(val) {
        if (!val) {
          store.commit('ui/SET_SHOWING_POI', false)
        }
      }
    },
    showingPhoto: {
      get() {
        return store.state.ui.showingPhoto
      },
      set(val) {
        if (!val) {
          store.commit('ui/SET_SHOWING_PHOTO', false)
        }
      }
    },
    showStreetView: {
      get() {
        return store.state.ui.showStreetView
      },
      set(val) {
        store.dispatch('map/toggleStreetView', val)
      }
    },
    lockStreetView: {
      get() {
        return store.state.ui.lockStreetView
      },
      set(val) {
        store.commit('ui/TOGGLE_LOCK_STREET_VIEW', val)
      }
    },
    playVideo: {
      get() {
        return store.state.ui.playVideo
      },
      set(val) {
        store.commit('ui/TOGGLE_PLAY_VIDEO', val)
      }
    },
    mapCenter: {
      get() {
        return store.state.ui.mapCenter
      },
      set(val) {
        store.commit('ui/SET_MAP_CENTER', val)
      }
    },
    showDismissSeconds: {
      get() {
        return store.state.ui.showDismissSeconds
      },
      set(val) {
        store.commit('ui/SET_SHOW_DISMISS_SECONDS', val)
      }
    }
  },
  methods: {
    toast(text, variant, position = 'top-right') {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text,
            variant
          }
        },
        {
          position
        }
      )
    },
    toastS(text, position = 'top-right') {
      this.toast(text, 'success', position)
    },
    toastW(text, position = 'top-right') {
      this.toast(text, 'warning', position)
    },
    toastE(err) {
      this.toastW(err.message)
    },
    showLoading() {
      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        appLoading.style.display = 'block'
      }
    },
    log(...args) {
      log(...args)
    }
  }
}
