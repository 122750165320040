<template>
  <l-map
    ref="map"
    :zoom="zoom"
    :center="center"
    :options="mapOptions"
    @ready="onMapReady"
    @click="({ latlng, originalEvent }) => $emit('click-map', latlng, null, originalEvent)"
    @move="$emit('change-map-center', map.getCenter())"
    @zoom="$emit('change-zoom', map.getZoom())"
    @baselayerchange="changeLayer"
  >
    <l-control v-for="(m, index) in mapButtons" :key="`mapButtons${index}`" position="topright">
      <b-button v-b-tooltip.hover.left="m.tooltip" variant="primary" class="btn-icon" @click="$emit(m.event)">
        <feather-icon :icon="m.icon" />
      </b-button>
    </l-control>

    <l-control-scale :imperial="false" position="bottomright" />
    <l-control-zoom position="bottomright" />

    <!-- <l-tile-layer :url="url" /> -->

    <!-- <l-control-layers position="topleft" :collapsed="true" :sort-layers="true" />
    <l-tile-layer
      v-for="tileProvider in tileProviders"
      :key="tileProvider.name"
      :name="tileProvider.name"
      :visible="tileProvider.visible"
      :url="tileProvider.url"
      :attribution="tileProvider.attribution"
      :token="token"
      layer-type="base"
    /> -->

    <l-control-attribution position="bottomleft" prefix="GeoTuber" />

    <l-polyline
      v-for="(path, index) in mapPathLines"
      :key="`line${index}`"
      :lat-lngs="path"
      :editable="false"
      :options="pathLine"
      @click="({ latlng }) => $emit('click-path', latlng)"
    />

    <l-polyline
      v-for="(path, index) in mapPathDots"
      :key="`dot${index}`"
      :lat-lngs="path"
      :editable="false"
      :options="pathDot"
      @click="({ latlng }) => $emit('click-path', latlng)"
    />

    <l-marker
      v-for="(m, index) in poiMarkers"
      :key="`poiMarker${index}`"
      :lat-lng="m.position"
      :clickable="true"
      :draggable="showSidebarRightEditTags && !playVideo"
      :icon="poiMarkerIcon"
      @click="$emit('click-poi-marker', m)"
      @dragend="e => $emit('move-tag-marker', m, e.target._latlng)"
    />

    <l-marker
      v-for="(m, index) in photoMarkers"
      :key="`photoMarker${index}`"
      :lat-lng="m.position"
      :clickable="true"
      :draggable="showSidebarRightEditTags && !playVideo"
      :icon="photoMarkerIcon"
      @click="$emit('click-photo-marker', m)"
      @dragend="e => $emit('move-tag-marker', m, e.target._latlng)"
    />

    <l-marker
      v-for="(m, index) in povMarkers"
      :key="`povMarker${index}`"
      :lat-lng="m.position"
      :clickable="true"
      :draggable="showSidebarRightEditTags && !playVideo"
      :icon="povMarkerIcon"
      @click="$emit('click-pov-marker', m)"
      @dragend="e => $emit('move-tag-marker', m, e.target._latlng)"
    />

    <l-polyline
      v-for="(path, index) in mapPathVideos"
      :key="`mapPathVideos${index}`"
      ref="mapPathVideos"
      :lat-lngs="path"
      :visible="true"
      :options="pathLineOptions(index)"
      @click="$emit('click-video', index)"
      @mouseover="focusIndex = index"
      @mouseout="focusIndex = -1"
    />

    <l-marker
      v-for="(m, index) in videoMarkers"
      :key="`videoMarker${index}`"
      :visible="true"
      :draggable="false"
      :lat-lng="m.position"
      :icon="pathMarkerIcon(index)"
      @mouseover="focusIndex = index"
      @mouseout="focusIndex = -1"
      @click="$emit('click-video', index)"
    />

    <div v-if="showSidebarRightEditTags">
      <l-marker
        v-for="(m, index) in selectedMarkers"
        :key="`selectedMarker${index}`"
        :lat-lng="m.position"
        :clickable="true"
        :draggable="showSidebarRightEditTags && !playVideo"
        :icon="selectedMarkerIcon"
        @click="$emit('click-tag-marker', m)"
        @dragend="e => $emit('move-tag-marker', m, e.target._latlng)"
      />
    </div>

    <v-marker-cluster v-if="showSidebarRightEditTags && !playVideo">
      <l-marker
        v-for="(m, index) in otherMarkers"
        :key="`otherMarker${index}`"
        :lat-lng="m.position"
        :clickable="true"
        :draggable="showSidebarRightEditTags && !playVideo"
        :icon="otherMarkerIcon"
        @click="$emit('click-tag-marker', m)"
        @dragend="e => $emit('move-tag-marker', m, e.target._latlng)"
      />
    </v-marker-cluster>

    <l-marker
      v-if="theMarker"
      key="theMarker"
      :lat-lng="theMarker.position"
      :icon="pathMarkerIcon()"
      :clickable="false"
      :draggable="true"
      @dragend="dragTheMarker"
    >
      <l-tooltip :options="tooltipOptions">
        lat={{ Math.floor(theMarker.position.lat * 1000000) / 1000000 }} lng={{
          Math.floor(theMarker.position.lng * 1000000) / 1000000
        }}
      </l-tooltip>
    </l-marker>
    <!-- <l-popup v-if="!!showingPoi" :lat-lng.sync="showingPoi.position">{{ showingPoi.n }}</l-popup> -->
  </l-map>
</template>

<script>
import store from '@/store'

import mixinUi from '@/store/ui/mixin'
import mixinMap from '@/store/map/mixin'

import { BButton, VBTooltip } from 'bootstrap-vue'

import L from 'leaflet'
import {
  LMap,
  // LTileLayer,
  LControlZoom,
  // LControlLayers,
  LControlAttribution,
  LControlScale,
  LPolyline,
  LMarker,
  LTooltip,
  // LPopup,
  LControl,
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import './leaflet-providers'
import 'leaflet.chinatmsproviders'

const mapPinRed = require('@/assets/images/marker/map-pin-red.png')
const mapPinOrange = require('@/assets/images/marker/map-pin-orange.png')

export default {
  components: {
    BButton,
    LMap,
    // LTileLayer,
    LControlZoom,
    // LControlLayers,
    LControlAttribution,
    LControlScale,
    LPolyline,
    LMarker,
    LTooltip,
    // LPopup,
    LControl,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [mixinUi, mixinMap],
  props: {
    center: {
      type: Object,
      required: false,
      default: () => ({ lat: 0, lng: 0 }),
    },
    zoom: {
      type: Number,
      required: false,
      default: 12,
    },
  },
  data() {
    return {
      map: null,
      mapOptions: {
        zoomControl: false,
        attributionControl: false,
        zoomSnap: true,
      },
      tooltipOptions: {
        offset: [0, -50],
        direction: 'top',
      },
      pathLine: {
        color: '#00acc1',
        weight: 5,
      },
      pathDot: {
        color: '#00acc1',
        weight: 5,
        dashArray: '5 10',
      },
      selectedMarkerIcon: L.icon({
        // eslint-disable-next-line global-require
        iconUrl: require('@/assets/images/marker/cycle18g.png'),
        iconSize: [18, 18],
        iconAnchor: [9, 9],
        // popupAnchor: [-3, -76],
        // shadowUrl: 'my-icon-shadow.png',
        // shadowSize: [68, 95],
        // shadowAnchor: [22, 94]
      }),
      otherMarkerIcon: L.icon({
        // eslint-disable-next-line global-require
        iconUrl: require('@/assets/images/marker/cycle18.png'),
        iconSize: [18, 18],
        iconAnchor: [9, 9],
        // popupAnchor: [-3, -76],
        // shadowUrl: 'my-icon-shadow.png',
        // shadowSize: [68, 95],
        // shadowAnchor: [22, 94]
      }),
      poiMarkerIcon: L.icon({
        // eslint-disable-next-line global-require
        iconUrl: require('@/assets/images/marker/poi-marker.png'),
        iconSize: [30, 30],
        iconAnchor: [15, 15],
        // popupAnchor: [-3, -76],
        // shadowUrl: 'my-icon-shadow.png',
        // shadowSize: [68, 95],
        // shadowAnchor: [22, 94]
      }),
      povMarkerIcon: L.icon({
        // eslint-disable-next-line global-require
        iconUrl: require('@/assets/images/marker/pov-marker.png'),
        iconSize: [30, 30],
        iconAnchor: [15, 15],
        // popupAnchor: [-3, -76],
        // shadowUrl: 'my-icon-shadow.png',
        // shadowSize: [68, 95],
        // shadowAnchor: [22, 94]
      }),
      photoMarkerIcon: L.icon({
        // eslint-disable-next-line global-require
        iconUrl: require('@/assets/images/marker/photo-marker.png'),
        iconSize: [30, 30],
        iconAnchor: [15, 15],
        // popupAnchor: [-3, -76],
        // shadowUrl: 'my-icon-shadow.png',
        // shadowSize: [68, 95],
        // shadowAnchor: [22, 94]
      }),
      // layersPosition: 'bottomright',
      popup: null,
      focusIndex: -1,
    }
  },
  computed: {
    streetView() {
      return store.state.map.streetView
    },
  },
  watch: {
    focusIndex() {
      this.$refs.mapPathVideos.forEach((line, index) => line.mapObject.setStyle(this.pathLineOptions(index)))
      this.$emit('focus-index', this.focusIndex)
    },
    showingPoi() {
      if (this.showingPoi) {
        this.popup = L.popup({ offset: L.point(0, 0), autoPan: false, closeButton: true, closeOnClick: false })
          .setLatLng(this.showingPoi.location)
          .setContent(`${this.showingPoi.n}&nbsp;&nbsp;<a href=${this.showingPoi.url} target="_blank">Open...</a>`)
          .openOn(this.map)
      } else if (this.popup) {
        this.popup.remove()
        this.popup = null
      }
    },
    streetView() {
      if (this.showStreetView) {
        const pos = store.state.map.streetView.position
        const pov = store.state.map.streetView.pov
        if (pos && pov) {
          this.popup = L.popup({ offset: L.point(0, 0), autoPan: false, closeButton: true, closeOnClick: false })
            .setLatLng(pos)
            .setContent(
              `Street View&nbsp;&nbsp;<a href="http://maps.google.com/maps?q=&layer=c&cbll=${pos.lat},${pos.lng}&cbp=11,${pov.heading},${pov.pitch},${pov.zoom},0" target="_blank">Open...</a>`
            )
            .openOn(this.map)
        }
      } else if (this.popup) {
        this.popup.remove()
        this.popup = null
      }
    },
  },
  mounted() {},
  methods: {
    onMapReady() {
      this.map = this.$refs.map.mapObject
      store.commit('map/SET_LEAFLET_MAP', this.map)

      const defaultBaseMap = L.tileLayer.provider('OpenStreetMap')
      // https://github.com/leaflet-extras/leaflet-providers
      // https://leaflet-extras.github.io/leaflet-providers/preview/index.html
      const baseMaps = {
        Default: defaultBaseMap,
        City: L.tileLayer.provider('MapBox', {
          id: 'mapbox/streets-v11',
          accessToken: 'pk.eyJ1Ijoib3JlbnFpIiwiYSI6ImNrcDBzbGt3ODE1d3EybnFvamU0MHA4bHgifQ.WZKdpHdJKk1v822Ui25j4g',
        }),
        Terrain: L.tileLayer.provider('Thunderforest.Landscape', {
          apikey: 'e5dc3af265c74c99b39177eae3bdf557',
        }),
        Satellite: L.tileLayer.provider('MapBox', {
          id: 'mapbox/satellite-streets-v11',
          accessToken: 'pk.eyJ1Ijoib3JlbnFpIiwiYSI6ImNrcDBzbGt3ODE1d3EybnFvamU0MHA4bHgifQ.WZKdpHdJKk1v822Ui25j4g',
        }),
        Night: L.tileLayer.provider('Jawg.Streets', {
          variant: 'jawg-dark',
          accessToken: 'vo9VJpHALK7K1HQvT6Ou6KX0AibtDl1mqMDy5eJXLw5O3aACrhaieOBPHTnpnLxf',
        }),

        // HEREv3: L.tileLayer.provider('HEREv3.normalDayGrey', {
        //   apiKey: 'g1vRGDbbataNOYMyV7CFGOo0MmGJieKpmUbUrtFtwAY',
        // }),
        // 'Jawg Maps': L.tileLayer.provider('Jawg.Streets', {
        //   variant: '',
        //   accessToken: 'vo9VJpHALK7K1HQvT6Ou6KX0AibtDl1mqMDy5eJXLw5O3aACrhaieOBPHTnpnLxf',
        // }),
        // Mapbox: L.tileLayer.provider('MapBox', {
        //   id: 'mapbox/satellite-v9',
        //   accessToken: 'pk.eyJ1Ijoib3JlbnFpIiwiYSI6ImNrcDBzbGt3ODE1d3EybnFvamU0MHA4bHgifQ.WZKdpHdJKk1v822Ui25j4g',
        // }),
        // 'MapTiler Cloud': L.tileLayer.provider('MapTiler.Topo', {
        //   key: 'MPvDPcOvdYAhzdbp47bG',
        // }),
        // Thunderforest: L.tileLayer.provider('Thunderforest.Landscape', {
        //   apikey: 'e5dc3af265c74c99b39177eae3bdf557',
        // }),
        // Esri: L.tileLayer.provider('Esri.WorldTopoMap'),
        // TomTom: L.tileLayer.provider('TomTom', {
        //   apikey: 'hnJ5gY7ioqCKrg5TxfA0QxxtPurP2NjW',
        // }),
        China: L.tileLayer.chinaProvider('GaoDe.Normal.Map'),
      }
      defaultBaseMap.addTo(this.map)
      L.control.layers(baseMaps, null, { position: 'topleft' }).addTo(this.map)

      setTimeout(() => {
        this.map.invalidateSize(false)
        this.$emit('map-inited')
      }, 800)
    },
    pathLineOptions(index) {
      return {
        color: index === this.focusIndex ? '#F8954D' : '#00acc1',
        weight: 6,
        zIndex: index === this.focusIndex ? 1 : 0,
      }
    },
    pathMarkerIcon(index) {
      return L.icon({
        iconUrl: index === this.focusIndex ? mapPinOrange : mapPinRed,
        iconSize: [32, 45],
        iconAnchor: [15, 45],
        // popupAnchor: [-3, -76],
        // shadowUrl: 'my-icon-shadow.png',
        // shadowSize: [68, 95],
        // shadowAnchor: [22, 94]
      })
    },
    dragTheMarker(e) {
      setTimeout(() => this.$emit('move-the-marker', e.target.getLatLng()), 100)
    },
    changeLayer(e) {
      this.$gtag.event('Leaflet Layer', { value: e.name })
    },
  },
}
</script>

<style lang="scss">
.vue2leaflet-map {
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  z-index: 0;
  &.leaflet-container {
    height: 100%;
  }
}

.dark-layout .leaflet-control-layers-base label {
  color: #5e5873 !important;
}
</style>
