<template>
  <div class="map-poi-overlay">
    <!-- swiper1 -->
    <swiper
      ref="swiperTop"
      class="swiper-gallery gallery-top"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide v-for="(data, index) in swiperData" :key="index">
        <div class="cover-image" :style="`background-image: url(${data.img});`" />
      </swiper-slide>

      <div slot="button-next" class="swiper-button-next swiper-button-white" />
      <div slot="button-prev" class="swiper-button-prev swiper-button-white" />
    </swiper>

    <!-- swiper2 Thumbs -->
    <swiper ref="swiperThumbs" class="swiper gallery-thumbs" :options="swiperOptionThumbs">
      <swiper-slide v-for="(data, index) in swiperData" :key="index">
        <div class="cover-image" :style="`background-image: url(${data.img});`" />
      </swiper-slide>
    </swiper>

    <div class="control-panel">
      <b-media vertical-align="top">
        <template #aside>
          <!-- <b-img v-if="poi.photo" :src="poi.photo" blank-color="#ccc" width="64" alt="placeholder" /> -->
          <b-button variant="flat-warning" class="btn-icon">
            <feather-icon icon="XIcon" @click="unsetPoi" />
          </b-button>
        </template>
        <!-- <h4 class="media-heading"></h4> -->
        <h4>{{ poi.name }}</h4>
        <b-link style="font-size: 90%" :href="poi.url" target="_blank">View on Google Maps</b-link>
      </b-media>
    </div>
  </div>
</template>

<script>
import mixinUi from '@/store/ui/mixin'
// import store from '@/store'
import {
  BButton,
  // BCard,
  // BCardText,
  BMedia,
  // BCardBody,
  // BCardHeader,
  // BCardTitle,
  BLink,
  // BCardSubTitle,
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import '@core/scss/base/plugins/extensions/ext-component-swiper.scss'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BButton,
    // BCard,
    // BCardText,
    BMedia,
    // BCardBody,
    // BCardHeader,
    // BCardTitle,
    BLink,
  },
  mixins: [mixinUi],
  props: {
    poi: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {}
  },
  computed: {
    photos() {
      if (this.poi && this.poi.photos) {
        return this.poi.photos
      }
      return []
    },
    swiperData() {
      return this.photos.map(photo => ({
        img: photo.url,
      }))
    },
    swiperOptions() {
      return {
        loop: true,
        loopedSlides: this.photos.length,
        spaceBetween: 10,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }
    },
    swiperOptionThumbs() {
      return {
        loop: true,
        loopedSlides: this.photos.length, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 5,
        touchRatio: 0.2,
        slideToClickedSlide: true,
      }
    },
  },
  watch: {
    poi() {},
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$swiper
      const swiperThumbs = this.$refs.swiperThumbs.$swiper
      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
    })
  },
  methods: {
    unsetPoi() {
      this.showingPoi = false // 点击 x
    },
  },
}
</script>

<style lang="scss">
.map-poi-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333;
}

.cover-image {
  width: 100%;
  height: 100%;
  background-color: #333;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-gallery {
  height: 86%;
  overflow: hidden;
}

.gallery-thumbs {
  height: 14%;
  overflow: hidden;
}

.map-poi-overlay {
  .control-panel {
    position: absolute;
    bottom: calc(14% + 20px);
    left: 20px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 10px;
    z-index: 1;
    border-radius: 10px;

    h4 {
      color: #fff;
    }

    a {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
</style>
