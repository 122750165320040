import { parseDMS } from '@/utils'

export default class GeoTag {
  seconds = null

  position = null

  pov = null

  placeKey = null

  placeId = null // TODO: to be removed

  photoId = null

  lock = false

  still = false

  disableEx = false

  showTime = false

  constructor({
    seconds,
    position,
    pov,
    placeId,
    placeKey,
    photoId,
    lock = false,
    still = false,
    disableEx = false,
    showTime = false
  }) {
    this.seconds = seconds
    this.position = position
    this.pov = pov
    this.placeId = placeId
    this.placeKey = placeKey
    this.photoId = photoId
    this.lock = lock
    this.still = still
    this.disableEx = disableEx
    this.showTime = showTime
  }

  copy(tag) {
    this.seconds = tag.seconds
    this.position = tag.position
    this.pov = tag.pov
    this.placeKey = tag.placeKey
    this.photoId = tag.photoId
    // this.lock = tag.lock
    // this.still = tag.still
    // this.disableEx = tag.disableEx
  }

  toJSON() {
    const json = {
      position: this.position,
      seconds: this.seconds
    }
    if (this.placeKey) {
      json.placeKey = this.placeKey
    }
    if (this.placeId) {
      json.placeId = this.placeId
    }
    if (this.pov) {
      json.pov = this.pov
    }
    if (this.photoId) {
      json.photoId = this.photoId
    }
    if (this.lock) {
      json.lock = this.lock
    }
    if (this.still) {
      json.still = this.still
    }
    if (this.disableEx) {
      json.disableEx = this.disableEx
    }
    if (this.showTime) {
      json.showTime = this.showTime
    }
    return json
  }

  static fromRaw(raw) {
    const parts = raw.split(';')
    const seconds = parseInt(parts[0], 10)
    const type = parts[1] // loc, poi
    const { lat, lng } = parseDMS(parts[2])

    if (type === 'poi') {
      const placeId = parts[3]
      return new GeoTag({ seconds, position: { lat, lng }, placeId })
    }

    return new GeoTag({ seconds, position: { lat, lng } })
  }
}
