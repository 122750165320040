<template>
  <div>
    <!-- https://keycode.info/ -->
    <Keypress key-event="keyup" :key-code="32" @success="evt => pressKey('space', evt)" />
    <Keypress key-event="keyup" :key-code="37" @success="evt => pressKey('left', evt)" />
    <Keypress key-event="keyup" :key-code="39" @success="evt => pressKey('right', evt)" />

    <video-map :layout="videoMapLayout" :show-overlay="showSidebarRightEditTags && (showGallery || showGalleryPhoto)">
      <template v-slot:video>
        <youtube
          ref="youtube"
          :fit-parent="false"
          :video-id="videoId"
          :player-vars="playerVars"
          @ready="ready"
          @playing="playing"
          @ended="ended"
          @paused="paused"
          @cued="cued"
        />
      </template>

      <template v-slot:map>
        <google-map
          v-if="useGoogleMap && isSuperUser"
          @map-inited="onMapInited"
          @click-map="clickMap"
          @click-path="clickPath"
          @move-tag-marker="moveTagMarker"
          @click-tag-marker="clickTagMarker"
          @click-poi-marker="clickPoiMarker"
          @click-photo-marker="clickPhotoMarker"
          @click-pov-marker="clickPovMarker"
          @move-the-marker="moveTheMarker"
          @open-street-view="openStreetView"
          @show-full-path="showFullPath"
          @goto-marker="gotoMarker"
        />
        <leaflet-map
          v-else
          @map-inited="onMapInited"
          @click-map="clickMap"
          @click-path="clickPath"
          @move-tag-marker="moveTagMarker"
          @click-tag-marker="clickTagMarker"
          @click-poi-marker="clickPoiMarker"
          @click-photo-marker="clickPhotoMarker"
          @click-pov-marker="clickPovMarker"
          @move-the-marker="moveTheMarker"
          @show-full-path="showFullPath"
          @goto-marker="gotoMarker"
        />

        <poi-overlay v-if="!!showingPoi && showingPoi.photos" :poi="showingPoi" />

        <photo-overlay v-if="!!showingPhoto" :photo="showingPhoto" />
      </template>

      <template v-slot:overlay>
        <div v-if="showGallery" class="app-content content">
          <div class="content-wrapper clearfix">
            <div class="content-detached content-right">
              <div class="content-wrapper">
                <div class="content-body">
                  <div class="ecommerce-application">
                    <view-gallery
                      :embedded="true"
                      @gallery-new-photo="galleryNewPhoto"
                      @gallery-click-photo="galleryClickPhoto"
                      @gallery-use-photo="selectGalleryPhoto"
                      @gallery-close="showGallery = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <portal-target name="content-renderer-sidebar-detached-left" slim />
          </div>
        </div>
        <div v-if="showGalleryPhoto" class="app-content content">
          <div class="content-wrapper">
            <div class="content-body">
              <div class="ecommerce-application">
                <view-gallery-photo
                  :embedded="true"
                  :photo-key="galleryPhotoId"
                  @gallery-photo-close="closeGalleryPhoto"
                  @gallery-use-photo="selectGalleryPhoto"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </video-map>

    <sidebar-edit
      :is-editing="showSidebarRightEditTags"
      @save-tag="saveTag"
      @toggle-video-play="toggleVideoPlay"
      @set-time="seekSeconds"
      @clear-route-tags="hideRoute"
      @add-route-tags="addRouteTags"
      @find-route="showRoute"
      @row-selected="setSelectedTags"
      @click-tag="clickTag"
      @hide="showSidebarRightEditTags = false"
      @show-gallery="showGallery = true"
    />

    <portal to="vertical-nav-menu-items-addon">
      <ul class="navigation navigation-main">
        <li class="navigation-header text-truncate">
          <span>Related Videos</span>
          <feather-icon icon="MoreHorizontalIcon" size="18" />
        </li>

        <li v-for="video in relatedVideos" :key="video.key" class="nav-item video-link">
          <b-link :to="{ name: 'video', params: { id: video.key } }">
            <b-aspect aspect="16:9" style="width: 200px">
              <div class="video" fluid :style="`background-image: url(${video.coverUrl});`" />
            </b-aspect>
          </b-link>
        </li>
      </ul>
    </portal>
  </div>
</template>

<script>
import mixinUi from '@/store/ui/mixin'
import mixinServer from '@/store/server/mixin'
import mixinTags from '@/store/tags/mixin'
import mixinMap from '@/store/map/mixin'

import { getDistance, getDistanceFromLine } from 'geolib'
import { GPOS, log } from '@/utils'
// import GeoTag from '@/model/geotag'
import store from '@/store'
import Keypress from 'vue-keypress'
import { BLink, BAspect } from 'bootstrap-vue'
import VideoMap from '@/layouts/LayoutVideoMap.vue'
import SidebarEdit from './parts/SidebarEdit.vue'
// import PoiInfoWindow from './parts/PoiInfoWindow.vue'
import PoiOverlay from './parts/PoiOverlay.vue'
import PhotoOverlay from './parts/PhotoOverlay.vue'

import GoogleMap from './parts/GoogleMap.vue'
import LeafletMap from './parts/LeafletMap.vue'

import ViewGallery from './ViewGallery.vue'
import ViewGalleryPhoto from './ViewGalleryPhoto.vue'

import mixinDebug from './mixinDebug'
// import { t } from '@/@core/libs/i18n/utils'

export default {
  name: 'ViewVideoMap',
  components: {
    VideoMap,
    SidebarEdit,
    // PoiInfoWindow,
    PoiOverlay,
    PhotoOverlay,
    GoogleMap,
    LeafletMap,
    BLink,
    BAspect,
    Keypress,
    ViewGallery,
    ViewGalleryPhoto,
  },
  mixins: [mixinUi, mixinTags, mixinMap, mixinServer, mixinDebug],
  data() {
    return {
      playerVars: {
        version: 3,
        autoplay: 1,
      },
      pathSegs: [],
      lastTime: -1,
      selectedTags: [],
      showGallery: false,
      showGalleryPhoto: false,
      galleryPhotoId: null,
      relatedVideos: [],
      shouldUpdateLocation: false,
      autoAddTag: false,
      mapInited: false,
    }
  },
  computed: {
    videoId() {
      return this.$route.params.id
    },
    player() {
      return this.$refs.youtube.player
    },
    google() {
      return store.getters['google/api']
    },
    videoMapLayout() {
      return store.state.appConfig.layout.layout
    },
    mapPath() {
      return this.tags.map(tag => tag.position)
    },
  },
  watch: {
    mapPath() {
      this.log('mapPath changed')
      this.pathSegs = []
      this.onTagsUpdate()
    },
    videoId() {
      this.log('videoId changed', this.videoId)

      this.lastTime = -1
      store.commit('ui/TOGGLE_SIDEBAR_RIGHT_EDIT_TAGS', false)
      this.hideRoute()
      store.dispatch('ui/reset')

      store
        .dispatch('tags/setVideoIdAndKey', { videoId: this.videoId })
        .then(localKey => {
          this.showFullPath()
          this.log('done', localKey)
          if (localKey) {
            this.$router.replace({
              name: 'video',
              params: { id: this.videoId },
              query: { key: localKey },
            })
          }
        })
        .catch(err => {
          if (err.localTagsKey) {
            this.log('redirect', err.localTagsKey)
            return this.$router.go({
              name: 'video',
              params: { id: this.videoId },
              query: { key: err.localTagsKey },
            })
          }
          this.log('got error')
          this.log(err)
          return null
        })

      this.getRelatedVideos()

      // this.player.cueVideoById(this.videoId, 0)
      // this.player.getDuration().then(time => {
      //   store.commit('tags/SET_TOTAL_SECONDS', time)
      //   this.progress()
      // })
    },
    tag() {
      // this.log('tag changed : ', this.tag)
      if (this.playVideo || !this.showSidebarRightEditTags) {
        if (this.mapInited) {
          this.checkTagEx() // 播放时，tag变化了 / 暂停时，且非编辑状态
        }
      }
    },
    playVideo() {
      if (this.playVideo) {
        this.checkTagEx() // 暂停转播放
      } else {
        // TODO: hide overlay
      }
    },
    // tags() {
    //   this.onTagsUpdate()
    // },
    selectedTags() {
      this.selectedMarkers = this.selectedTags.filter(
        tag => (!tag.pov && !tag.placeKey && !tag.photoId) || tag.disableEx
      )
      this.setOtherMarkers()
    },
    currentLocation() {
      this.theMarker = {
        position: this.currentLocation,
        title: 'the marker',
      }
    },
  },
  // eslint-disable-next-line no-unused-vars
  beforeRouteEnter(to, from, next) {
    store
      .dispatch('tags/setVideoIdAndKey', { videoId: to.params.id, key: to.query.key })
      .then(localKey => {
        if (localKey) {
          return next({ name: 'video', params: { id: to.params.id }, query: { key: localKey } })
        }
        return next()
      })
      .catch(err => {
        if (err.localTagsKey) {
          return next({ name: 'video', params: { id: to.params.id }, query: { key: err.localTagsKey } })
        }
        log('got error')
        return next({ name: 'error-404' })
      })
  },
  beforeMount() {
    this.showSidebarRight = false
    this.showNavibarSearchPoi = true
    this.showNavibarSearchVideo = false
    this.showNavibarLayout = true
    this.showNavibarEditTags = true

    store.dispatch('map/reset')

    if (this.useGoogleMap && this.isSuperUser) {
      this.mapButtons = [
        {
          icon: 'UserIcon',
          event: 'open-street-view',
          tooltip: 'open street view',
        },
        {
          icon: 'MaximizeIcon',
          event: 'show-full-path',
          tooltip: 'show full path',
        },
        {
          icon: 'MapPinIcon',
          event: 'goto-marker',
          tooltip: 'goto marker',
        },
      ]
    } else {
      this.mapButtons = [
        {
          icon: 'MaximizeIcon',
          event: 'show-full-path',
          tooltip: 'show full path',
        },
        {
          icon: 'MapPinIcon',
          event: 'goto-marker',
          tooltip: 'goto marker',
        },
      ]
    }

    this.getRelatedVideos()
  },
  mounted() {
    this.startTimer()
  },
  beforeDestroy() {
    // this.unwatchEditing()
    this.stopTimer()
  },
  methods: {
    onMapInited() {
      if (this.tag) {
        this.checkTagEx() // 针对第一个tag
      }

      if (this.tags) {
        this.onTagsUpdate()
      }

      setTimeout(() => {
        this.showFullPath()
      }, 500)

      this.mapInited = true
    },
    onTagsUpdate() {
      // this.log('---', this.tags)

      const solidLines = []
      const dotsLines = []
      let solidLine = []
      let dotsLine = []
      for (let i = 0; i < this.tags.length; i++) {
        const tag = this.tags[i]
        if (tag.still) {
          if (solidLine.length > 0) {
            solidLine.push(tag.position)
            solidLines.push(solidLine)
            solidLine = []
          }
          dotsLine.push(tag.position)
        } else {
          if (dotsLine.length > 0) {
            dotsLine.push(tag.position)
            dotsLines.push(dotsLine)
            dotsLine = []
          }
          solidLine.push(tag.position)
        }
      }
      if (solidLine.length > 0) {
        solidLines.push(solidLine)
      }
      if (dotsLine.length > 0) {
        dotsLines.push(dotsLine)
      }
      this.mapPathLines = solidLines
      this.mapPathDots = dotsLines

      this.poiMarkers = this.tags.filter(tag => !!tag.placeKey && !tag.disableEx)
      this.povMarkers = this.tags.filter(tag => !!tag.pov && !tag.disableEx)
      this.photoMarkers = this.tags.filter(tag => !!tag.photoId && !tag.disableEx)

      this.setOtherMarkers()
    },
    setOtherMarkers() {
      const markers = this.tags.filter(tag => (!tag.pov && !tag.placeKey && !tag.photoId) || tag.disableEx)
      this.selectedMarkers.forEach(tag => {
        const i = markers.indexOf(tag)
        if (i > -1) {
          markers.splice(i, 1)
        }
      })
      this.otherMarkers = markers
    },
    getRelatedVideos() {
      store.dispatch('server/getRelatedVideos', this.videoId).then(res => {
        this.log(res)
        this.relatedVideos = res.rows
      })
    },
    progress() {
      this.player.getCurrentTime().then(time => {
        if (time !== this.lastTime) {
          if (this.playVideo) {
            this.progressWhenPlaying(time)
          } else {
            this.progressWhenNotPlaying(time)
          }

          this.lastTime = time
        }
      })
    },
    progressWhenPlaying(time) {
      // this.log('progressWhenPlaying')
      store.dispatch('tags/setSeconds', time)
      if (!this.showStreetView || !this.lockStreetView) {
        store.dispatch('tags/updateLocation')
      }
      if (this.showingPoi || this.showingPhoto) {
        if (time >= this.showDismissSeconds) {
          store.dispatch('ui/hideShowingOverlay') // 显示时间到
          // store.dispatch('tags/setPoi', false) // 显示时间到
          // store.dispatch('tags/setPhoto', false) // 显示时间到
          this.showDismissSeconds = 0
        }
      }
    },
    progressWhenNotPlaying(time) {
      this.log('progressWhenNotPlaying', time)

      store.dispatch('tags/setSeconds', time)

      if (!this.showSidebarRightEditTags) {
        store.dispatch('tags/updateLocation')

        if (!store.dispatch('map/positionInBounds', this.theMarker.position)) {
          this.gotoMarker()
        }
      }

      // when, click path
      if (this.showSidebarRightEditTags) {
        if (this.shouldUpdateLocation) {
          // this.clickTagMarker(this.tags[i])
          this.log('tags/updateLocation')
          store.dispatch('tags/updateLocation')
          this.shouldUpdateLocation = false
        }

        if (this.autoAddTag) {
          this.saveTag()
          this.autoAddTag = false
        }
      }
    },
    startTimer() {
      this.stopTimer()
      if (!this.timer) {
        this.timer = setInterval(this.progress, 100)
        this.log('TIMER start')
      }
    },
    stopTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
        delete this.timer
        this.log('TIMER stop')
      }
    },
    ready() {
      this.log('video ready')
      // this.player.setPlaybackRate(2) // TEST ONLY
      this.player.getDuration().then(time => {
        store.commit('tags/SET_TOTAL_SECONDS', time)
        this.progress()
      })
    },
    cued() {
      this.log('video cued')
      this.player.getDuration().then(time => {
        store.commit('tags/SET_TOTAL_SECONDS', time)
        this.progress()
      })
    },
    playing() {
      this.log('playing')
      this.playVideo = true
      this.progress()
    },
    ended() {
      this.log('ended')
      this.playVideo = false
    },
    paused() {
      // https://stackoverflow.com/questions/18138031/how-to-distinguish-seek-from-pause-via-youtube-player-iframe-api
      setTimeout(() => {
        this.player.getPlayerState().then(state => {
          if (state === 2) {
            this.log('paused')
            this.playVideo = false
            this.progress()
          }
        })
      }, 500)
    },
    saveTag() {
      store.dispatch('tags/saveTag')
    },
    // 调用情况
    // 1 播放时，tag变化了
    // 2 暂停时，且非编辑状态，tag变化了（拖动时间、点击路径）
    // 3 暂停转播放
    // 4 刚打开界面，第一个tag
    checkTagEx() {
      this.checkTagPoi()
      this.checkTagPov()
      this.checkTagPhoto()
    },
    displayPoi(poi) {
      // this.log('displayPoi', poi)
      store.dispatch('ui/showPoi', poi)
      this.showDismissSeconds = this.currentSeconds + (this.tag.showTime || 30)
      this.gotoMarker()
    },
    displayPhoto(photoId) {
      store.dispatch('ui/showPhoto', photoId)
      this.showDismissSeconds = this.currentSeconds + (this.tag.showTime || 10)
    },
    checkTagPoi() {
      // this.log('checkTagPoi')
      // this.log(this.tag)
      if (!this.showStreetView || !this.lockStreetView) {
        // 非街景模式
        if (this.tag.placeKey && !this.tag.disableEx) {
          // 当前 tag 有 POI
          this.log(this.tag)
          store.dispatch('server/getPoi', this.tag.placeKey).then(poi => {
            this.log(poi)
            this.displayPoi(poi)

            if (!this.lockPoi) {
              store.commit('tags/SET_POI', poi)
            }
          })
        } else {
          // 当前 tag 没有 POI
          // eslint-disable-next-line no-lonely-if
          if (!this.playVideo) {
            // 非播放状态
            if (this.showingPoi) {
              store.commit('ui/SET_SHOWING_POI', false)
            }
          }
          if (!this.lockPoi) {
            store.commit('tags/SET_POI', false)
          }
        }
      }
    },
    checkTagPhoto() {
      // this.log('checkTagPhoto')
      // this.log(this.tag)
      if (!this.showStreetView || !this.lockStreetView) {
        // 非街景模式
        if (this.tag.photoId && !this.tag.disableEx) {
          // 当前 tag 有 Photo
          store.dispatch('server/getPhoto', this.tag.photoId).then(photo => {
            this.displayPhoto(photo.key)

            if (!this.lockPhoto) {
              store.commit('tags/SET_PHOTO', photo)
            }
          })
        } else {
          // 当前 tag 没有 Photo
          // eslint-disable-next-line no-lonely-if
          if (!this.playVideo) {
            // 非播放状态
            if (this.showingPhoto) {
              store.commit('ui/SET_SHOWING_PHOTO', false)
            }
          }
          if (!this.lockPhoto) {
            store.commit('tags/SET_PHOTO', false)
          }
        }
      }
    },
    checkTagPov() {
      // this.log('checkTagPov')
      // http://maps.google.com/maps?q=&layer=c&cbll={lat},{lng}&cbp=11,{heading},{pitch},{zoom},0
      if (!this.showStreetView || !this.lockStreetView) {
        if (this.tag.pov && !this.tag.disableEx) {
          this.showStreetView = true // 当前 tag 有 POV
          store.dispatch('map/setStreetView', { position: this.tag.position, pov: this.tag.pov })
          store.dispatch('map/panToPosition', this.tag.position)
        } else if (this.showStreetView) {
          this.showStreetView = false // 当前 tag 没有 POV
        }
      }
    },
    clickMap(latLng, placeId, domEvent) {
      if (placeId) {
        // 点击地图上各种图标
        store.dispatch('server/getPoiByPlaceId', placeId).then(poi => {
          store.dispatch('tags/setLocation', poi.location)

          this.displayPoi(poi)

          if (!this.lockPoi) {
            store.commit('tags/SET_POI', poi)
          }
        })
      } else {
        this.moveTheMarker(latLng)

        if (domEvent.metaKey && !this.playVideo && this.showSidebarRightEditTags) {
          // press command key, auto add tag

          let seconds = store.state.tags.currentSeconds + 1
          if (seconds >= store.state.tags.totalSeconds) {
            seconds = store.state.tags.totalSeconds - 1
          }
          this.autoAddTag = true
          this.seekSeconds(seconds)
        }
      }
    },
    // eslint-disable-next-line consistent-return
    clickPoiMarker(tag) {
      if (this.showSidebarRightEditTags) {
        // 编辑状态
        if (this.playVideo) {
          // 播放时
          this.seekSeconds(tag.seconds)
          if (tag === this.tag) {
            this.checkTagPoi() // 点击图标
          }
        } else {
          // 暂停时
          // this.clickTagMarker(tag)
          this.seekSeconds(tag.seconds)
          store.dispatch('tags/setLocation', tag.position)
          if (tag === this.tag) {
            this.checkTagPoi() // 点击图标
          } else {
            if (!this.lockPhoto) {
              store.commit('tags/SET_PHOTO', false)
            }
            if (!this.lockPoi) {
              store.dispatch('server/getPoi', tag.placeKey).then(poi => {
                store.commit('tags/SET_POI', poi)
              })
            }
          }
        }
      } else {
        // 非编辑状态
        // eslint-disable-next-line no-lonely-if
        if (this.playVideo) {
          // 播放时
          this.seekSeconds(tag.seconds)
          if (tag === this.tag) {
            this.checkTagPoi() // 点击图标
          }
        } else {
          // 暂停时
          this.seekSeconds(tag.seconds)
          if (tag === this.tag) {
            this.checkTagPoi() // 点击图标
          }
        }
      }
    },
    // eslint-disable-next-line consistent-return
    clickPhotoMarker(tag) {
      if (this.showSidebarRightEditTags) {
        // 编辑状态
        if (this.playVideo) {
          // 播放时
          this.seekSeconds(tag.seconds)
          if (tag === this.tag) {
            this.checkTagPhoto() // 点击图标
          }
        } else {
          // 暂停时
          // this.clickTagMarker(tag)
          this.seekSeconds(tag.seconds)
          store.dispatch('tags/setLocation', tag.position)
          if (tag === this.tag) {
            this.checkTagPhoto() // 点击图标
          } else {
            if (!this.lockPoi) {
              store.commit('tags/SET_POI', false)
            }
            if (!this.lockPhoto) {
              store.dispatch('server/getPhoto', tag.photoId).then(photo => {
                store.commit('tags/SET_PHOTO', photo)
              })
            }
          }
        }
      } else {
        // 非编辑状态
        if (this.playVideo) {
          // 播放时
          this.seekSeconds(tag.seconds)
          if (tag === this.tag) {
            this.checkTagPhoto() // 点击图标
          }
        } else {
          // 暂停时
          this.seekSeconds(tag.seconds)
          if (tag === this.tag) {
            this.checkTagPhoto() // 点击图标
          }
        }
      }
    },
    // eslint-disable-next-line consistent-return
    clickPovMarker(tag) {
      if (this.showSidebarRightEditTags) {
        // 编辑状态
        if (this.playVideo) {
          // 播放时
          this.seekSeconds(tag.seconds)
          if (tag === this.tag) {
            this.checkTagPov() // 点击图标
          }
        } else {
          // 暂停时
          // this.clickTagMarker(tag)
          this.seekSeconds(tag.seconds)
          store.dispatch('tags/setLocation', tag.position)
          if (tag === this.tag) {
            this.checkTagPov() // 点击图标
          }
        }
      } else {
        // 非编辑状态
        // eslint-disable-next-line no-lonely-if
        if (this.playVideo) {
          // 播放时
          this.seekSeconds(tag.seconds)
          if (tag === this.tag) {
            this.checkTagPov() // 点击图标
          }
        } else {
          // 暂停时
          this.seekSeconds(tag.seconds)
          if (tag === this.tag) {
            this.checkTagPov() // 点击图标
          }
        }
      }
    },
    clickTagMarker(tag) {
      this.clickTag(tag)
    },
    clickTag(tag) {
      // this.log(tag)
      this.log('clickTag')
      this.seekSeconds(tag.seconds)
      if (!this.playVideo) {
        store.dispatch('tags/setLocation', tag.position)

        if (!store.dispatch('map/positionInBounds', tag.position)) {
          store.dispatch('map/panToPosition', tag.position)
        }

        if (this.showStreetView && this.lockStreetView) {
          store.dispatch('map/setStreetView', { position: tag.position, pov: tag.pov })
        } else {
          // eslint-disable-next-line no-lonely-if
          if (tag.placeKey) {
            if (!this.lockPoi) {
              store.dispatch('server/getPoi', tag.placeKey).then(poi => {
                this.displayPoi(poi)

                store.commit('tags/SET_POI', poi)
              })
            }
          } else {
            if (this.showingPoi) {
              store.commit('ui/SET_SHOWING_POI', false)
            }
            if (!this.lockPoi) {
              store.commit('tags/SET_POI', false)
            }
          }

          if (tag.photoId) {
            if (!this.lockPhoto) {
              store.dispatch('server/getPhoto', tag.photoId).then(photo => {
                this.displayPhoto(photo.key)

                store.commit('tags/SET_PHOTO', photo)
              })
            }
          } else {
            if (this.showingPhoto) {
              store.commit('ui/SET_SHOWING_PHOTO', false)
            }
            if (!this.lockPhoto) {
              store.commit('tags/SET_PHOTO', false)
            }
          }
        }
      }
    },
    moveTagMarker(tag, latLng) {
      // this.log(tag, latLng)
      if (this.showSidebarRightEditTags && !this.playVideo) {
        store.dispatch('tags/setLocation', latLng)
        store.dispatch('tags/editTag', { tag, key: 'position', value: latLng })
        this.clickTag(tag)
      }
    },
    moveTheMarker(latLng) {
      store.dispatch('tags/setLocation', latLng)
    },
    // eslint-disable-next-line consistent-return
    clickPath(latLng) {
      let s = 0
      let i = 0
      let minDistance = 9999
      let minIndex = 0

      for (; i < this.mapPath.length - 1; i++) {
        const d = getDistanceFromLine(GPOS(latLng), GPOS(this.mapPath[i]), GPOS(this.mapPath[i + 1]))

        if (d < minDistance) {
          minDistance = d
          minIndex = i
        }
      }

      i = minIndex

      if (i < this.mapPath.length - 1) {
        if (this.tags[i].still) {
          s = this.tags[i].seconds
        } else {
          const d = getDistance(GPOS(latLng), GPOS(this.mapPath[i]))
          if (!this.pathSegs[i]) {
            this.pathSegs[i] = {
              distance: getDistance(GPOS(this.mapPath[i]), GPOS(this.mapPath[i + 1])),
            }
          }
          const r = d / this.pathSegs[i].distance
          s = this.tags[i].seconds + r * (this.tags[i + 1].seconds - this.tags[i].seconds)
        }

        this.shouldUpdateLocation = true

        this.log('seekSeconds', s)
        this.seekSeconds(s)
      }
    },
    seekSeconds(seconds) {
      this.player.seekTo(seconds)
    },
    setSelectedTags(tags) {
      const orderedTags = []
      tags.forEach(tag => {
        let i = 0
        for (; i < orderedTags.length; i++) {
          if (orderedTags[i].seconds > tag.seconds) {
            break
          }
        }
        if (i > orderedTags.length - 1) {
          orderedTags.push(tag)
        } else {
          orderedTags.splice(i, 0, tag)
        }

        return tag
      })
      this.selectedTags = orderedTags
    },
    showRoute() {
      store.dispatch('map/showRoute', { tags: this.selectedTags }).then(route => {
        store.commit('tags/SET_ROUTE', route)
      })
    },
    hideRoute() {
      store.dispatch('map/hideRoute')
      store.commit('tags/SET_ROUTE', false)
    },
    addRouteTags() {
      store.dispatch('map/hideRoute')
      store.dispatch('tags/addRouteTags')
    },
    toggleVideoPlay() {
      if (this.playVideo) {
        this.player.pauseVideo()
      } else {
        this.player.playVideo()
      }
    },
    gotoMarker() {
      if (this.theMarker) {
        store.dispatch('map/panToPosition', this.theMarker.position)
      }
    },
    showFullPath() {
      if (this.mapPath && this.mapPath.length > 0) {
        this.log('showFullPath')
        store.dispatch('map/fitBounds', this.mapPath)
      }
    },
    openStreetView() {
      if (!this.playVideo) {
        this.showStreetView = true
        if (this.showSidebarRightEditTags) {
          this.lockStreetView = true
        }

        store.dispatch('map/setStreetView', { position: this.theMarker.position })
      }
    },
    hideStreetView() {
      this.showStreetView = false
    },
    pressKey(keyName, { event }) {
      if (!document.activeElement || document.activeElement.tagName !== 'INPUT') {
        this.log(`pressKey : ${keyName}`)
        if (keyName === 'space') {
          if (this.playVideo) {
            this.player.pauseVideo()
          } else {
            this.player.playVideo()
          }
        } else if (keyName === 'right') {
          let seconds = store.state.tags.currentSeconds + (event.shiftKey ? 1 : 10)
          if (seconds >= store.state.tags.totalSeconds) {
            seconds = store.state.tags.totalSeconds - 1
          }
          this.seekSeconds(seconds)
        } else if (keyName === 'left') {
          let seconds = store.state.tags.currentSeconds - (event.shiftKey ? 1 : 10)
          if (seconds < 0) {
            seconds = 0
          }
          this.seekSeconds(seconds)
        }
      }
    },
    galleryClickPhoto(photo) {
      this.galleryPhotoId = photo.key
      this.showGallery = false
      this.showGalleryPhoto = true
    },
    galleryNewPhoto() {
      this.galleryPhotoId = null
      this.showGallery = false
      this.showGalleryPhoto = true
    },
    closeGalleryPhoto() {
      this.showGallery = true
      this.showGalleryPhoto = false
    },
    selectGalleryPhoto(photoId) {
      this.log(photoId)
      this.showGallery = false
      this.showGalleryPhoto = false
      store.dispatch('server/getPhoto', photoId).then(photo => {
        this.displayPhoto(photo.key)

        if (!this.lockPhoto) {
          store.commit('tags/SET_PHOTO', photo)
        }
      })
    },
  },
}
</script>

<style lang="scss">
.expanded {
  .video-link {
    a {
      // align-items: flex-start !important;
      margin-left: calc(2rem + 0.286rem) !important;
      margin-top: 20px !important;
      margin-bottom: 10px !important;
      padding: 0 !important;
    }

    .video {
      background-color: #000;
      width: 100%;
      height: 100%;
      // height: 280px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.video-link {
  a {
    display: none;
  }
}
</style>
