<template>
  <div class="map-photo-overlay" :style="`background-image: url(${photo.url});`">
    <div class="control-panel">
      <b-media vertical-align="top">
        <template #aside>
          <!-- <b-img v-if="poi.photo" :src="poi.photo" blank-color="#ccc" width="64" alt="placeholder" /> -->
          <b-button variant="flat-warning" class="btn-icon">
            <feather-icon icon="XIcon" @click="unsetPhoto" />
          </b-button>
        </template>
        <!-- <h4 class="media-heading"></h4> -->
        <h4>{{ photo.t }}</h4>
      </b-media>
    </div>
  </div>
</template>

<script>
import mixinUi from '@/store/ui/mixin'
// import store from '@/store'
import {
  BButton,
  // BCard,
  // BCardText,
  BMedia,
  // BCardBody,
  // BCardHeader,
  // BCardTitle,
  // BLink,
  // BCardSubTitle,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    // BCard,
    // BCardText,
    BMedia,
    // BCardBody,
    // BCardHeader,
    // BCardTitle,
    // BLink,
  },
  mixins: [mixinUi],
  props: {
    photo: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {}
  },
  computed: {
    photos() {
      if (this.photo) {
        return [this.photo.url]
      }
      return []
    },
  },
  watch: {},
  mounted() {},
  methods: {
    unsetPhoto() {
      this.showingPhoto = false // 点击 x
    },
  },
}
</script>

<style lang="scss">
.map-photo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;

  .cover-image {
    width: 100%;
    height: 100%;
    background-color: #333;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .control-panel {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 10px;
    z-index: 1;
    border-radius: 10px;

    h4 {
      color: #fff;
    }

    a {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
</style>
