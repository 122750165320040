<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ playVideo ? 'Playing' : 'Paused' }}</b-card-title>
      <b-card-title class="text-primary">
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" class="btn-icon rounded-circle">
          <feather-icon :icon="`${playVideo ? 'Pause' : 'Play'}Icon`" @click="$emit('toggle-video-play')" />
        </b-button>
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <!-- form -->
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group label="Time" label-for="time" class="mb-2">
              <!-- <cleave
                id="time"
                ref="time-input"
                v-model="time"
                class="form-control"
                :raw="false"
                :options="options.time"
                :readonly="playVideo"
                placeholder="hh:mm:ss"
              /> -->
              <b-form-spinbutton
                id="time"
                v-model="time"
                :formatter-fn="fmtTime"
                min="0"
                :max="totalSeconds"
                @change="changeTime"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Total" label-for="total-time" class="mb-2">
              <b-form-input id="total-time" :value="totalTime" readonly />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Lat" label-for="expiry" class="mb-2">
              <b-form-input id="lat" v-model="lat" :readonly="playVideo || adjustTime" />
              <!-- <b-input-group>
                <cleave
                  id="lat"
                  ref="lat-input"
                  v-model="lat"
                  class="form-control"
                  :raw="false"
                  :options="options.lat"
                  :readonly="playVideo"
                  placeholder=""
                  @keyup.enter.native="setLat"
                />
                <b-input-group-append>
                  <b-button variant="outline-primary" :disabled="playVideo" @click="clickLatSuffix">
                    {{ latSuffix }}
                  </b-button>
                </b-input-group-append>
              </b-input-group> -->
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Lng" label-for="cvv-cvc" class="mb-2">
              <b-form-input id="lng" v-model="lng" :readonly="playVideo || adjustTime" />
              <!-- <b-input-group>
                <cleave
                  id="lng"
                  ref="lng-input"
                  v-model="lng"
                  class="form-control"
                  :raw="false"
                  :options="Math.abs(lng) < 100 ? options.lat : options.lng"
                  :readonly="playVideo"
                  placeholder=""
                  @keyup.enter.native="setLng"
                />
                <b-input-group-append>
                  <b-button variant="outline-primary" :disabled="playVideo" @click="clickLngSuffix">
                    {{ lngSuffix }}
                  </b-button>
                </b-input-group-append>
              </b-input-group> -->
            </b-form-group>
          </b-col>

          <b-col v-if="poi" cols="12">
            <b-card no-body class="border-secondary border-lighten-5 compact">
              <b-card-header>
                <b-card-title>{{ poi.n }}</b-card-title>
                <!-- <b-card-sub-title>Edit geo tags</b-card-sub-title> -->
                <div>
                  <feather-icon
                    :opacity="lockPoi ? 1.0 : 0.5"
                    icon="LockIcon"
                    size="13"
                    class="cursor-pointer"
                    @click="toggleLockPoi"
                  />
                  <feather-icon v-if="!lockPoi" icon="XIcon" size="18" class="cursor-pointer" @click="unsetPoi" />
                </div>
              </b-card-header>
              <b-card-body>
                <b-media vertical-align="top">
                  <template #aside>
                    <!-- <b-img v-if="poi.photo" :src="poi.photo" blank-color="#ccc" width="64" alt="placeholder" /> -->
                    <div
                      v-if="poi.photos && poi.photos.length > 0"
                      class="poi-image"
                      :style="`background-image: url(${poi.photos[0].url});`"
                    />
                  </template>
                  <!-- <h4 class="media-heading"></h4> -->
                  <b-card-text class="mb-0">{{ poi.a }}</b-card-text>
                  <b-link style="font-size: 90%; margin-right: 20px" @click="toggleViewPoi">
                    {{ showingPoi ? 'Hide' : 'Show' }}
                  </b-link>
                  <b-link style="font-size: 90%" :href="poi.url" target="_blank">Google Maps</b-link>
                </b-media>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col v-if="photo" cols="12">
            <b-card no-body class="border-secondary border-lighten-5 compact">
              <b-card-header>
                <b-card-title>{{ photo.t }}</b-card-title>
                <!-- <b-card-sub-title>Edit geo tags</b-card-sub-title> -->
                <div>
                  <feather-icon
                    :opacity="lockPhoto ? 1.0 : 0.5"
                    icon="LockIcon"
                    size="13"
                    class="cursor-pointer"
                    @click="toggleLockPhoto"
                  />
                  <feather-icon v-if="!lockPhoto" icon="XIcon" size="18" class="cursor-pointer" @click="unsetPhoto" />
                </div>
              </b-card-header>
              <b-card-body>
                <b-media vertical-align="top">
                  <template #aside>
                    <!-- <b-img v-if="poi.photo" :src="poi.photo" blank-color="#ccc" width="64" alt="placeholder" /> -->
                    <div class="poi-image" :style="`background-image: url(${photo.url});`" />
                  </template>
                  <!-- <h4 class="media-heading"></h4> -->
                  <b-card-text class="mb-0">{{ photo.desc }}</b-card-text>
                  <b-link style="font-size: 90%; margin-right: 20px" @click="toggleViewPhoto">
                    {{ showingPhoto ? 'Hide' : 'View' }}
                  </b-link>
                </b-media>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col v-if="pov" cols="12">
            <b-card no-body class="border-secondary border-lighten-5 compact">
              <b-card-header>
                <b-card-title>Street View</b-card-title>
                <!-- <b-card-sub-title>Edit geo tags</b-card-sub-title> -->
                <feather-icon icon="XIcon" size="18" class="cursor-pointer" @click="hideStreetView" />
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col cols="4">
                    <b-form-group label="Heading" label-for="heading" class="mb-2">
                      <b-form-input id="heading" :value="heading" readonly />
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group label="Pitch" label-for="pitch" class="mb-2">
                      <b-form-input id="pitch" :value="pitch" readonly />
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group label="Zoom" label-for="zoom" class="mb-2">
                      <b-form-input id="zoom" :value="zoom" readonly />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              :disabled="playVideo || adjustTime"
              @click="$emit('save-tag')"
            >
              Save Tag
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <!--/ form -->
    </b-card-body>
  </b-card>
</template>

<script>
import mixinUi from '@/store/ui/mixin'
import mixinTags from '@/store/tags/mixin'
import {
  BMedia,
  // BImg,
  BForm,
  BFormInput,
  // BInputGroup,
  // BInputGroupPrepend,
  // BInputGroupAppend,
  BFormSpinbutton,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BCard,
  BCardText,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BLink,
  // BCardSubTitle,
} from 'bootstrap-vue'

// import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'

import store from '@/store'

import { convertDMSToDD, fmtTime } from '@/utils'

function fmtLatLng(v) {
  return Math.floor(v * 1000000) / 1000000
}

export default {
  components: {
    BMedia,
    // BImg,
    BForm,
    BFormInput,
    // BInputGroup,
    // BInputGroupPrepend,
    // BInputGroupAppend,
    BFormSpinbutton,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BLink,
    // BCardSubTitle,
    // Cleave,
    // Ripple,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [mixinUi, mixinTags],
  props: {},
  data() {
    return {
      options: {
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
        lat: {
          delimiters: ['°', "'", '.', '"'],
          blocks: [2, 2, 2, 1, 0],
          numericOnly: true,
        },
        lng: {
          delimiters: ['°', "'", '.', '"'],
          blocks: [3, 2, 2, 1, 0],
          numericOnly: true,
        },
      },
      latVal: '',
      // latSuffix: 'N',
      lngVal: '',
      // lngSuffix: 'W',
    }
  },
  computed: {
    lat: {
      get() {
        return fmtLatLng(store.state.tags.location.lat)
      },
      set(val) {
        store.dispatch('tags/setLat', parseFloat(val))
        // if (document.activeElement.id === 'lat') {
        //   this.latVal = val
        // }
      },
    },
    lng: {
      get() {
        return fmtLatLng(store.state.tags.location.lng)
      },
      set(val) {
        store.dispatch('tags/setLng', parseFloat(val))
        // if (document.activeElement.id === 'lng') {
        //   this.lngVal = val
        // }
      },
    },
    time: {
      get() {
        return Math.floor(store.state.tags.currentSeconds)
      },
      set(val) {
        if (document.activeElement.id === 'time') {
          this.log('set time', val)
          // this.setTime(parseTime(val))
        }
      },
    },
    totalTime() {
      return fmtTime(this.totalSeconds)
    },
    heading() {
      return this.pov ? this.pov.heading : '-'
    },
    pitch() {
      return this.pov ? this.pov.pitch : '-'
    },
    zoom() {
      return this.pov ? this.pov.zoom : '-'
    },
  },
  watch: {
    time() {
      if (this.adjustTime) {
        this.log('adjest time : ', this.time)
        this.workingTag.seconds = this.time
      }
    },
    // lat() {
    //   // this.latSuffix = this.lat.substring(this.lat.length - 1)
    // },
    // lng() {
    //   // this.lngSuffix = this.lng.substring(this.lng.length - 1)
    // },
  },
  methods: {
    fmtTime(val) {
      return fmtTime(val)
    },
    changeTime(val) {
      this.$emit('set-time', val)
    },
    decTime() {},
    incTime() {},
    setLat() {
      const parts = this.latVal.split(/[^\d\w.]+/)
      const lat = convertDMSToDD(parts[0], parts[1], parts[2], this.latSuffix)
      this.log(this.latVal, parts, lat)

      // store.dispatch('tags/setLat', lat)
    },
    setLng() {
      const parts = this.lngVal.split(/[^\d\w.]+/)
      const lng = convertDMSToDD(parts[0], parts[1], parts[2], this.lngSuffix)
      this.log(this.lngVal, parts, lng)

      // store.dispatch('tags/setLng', lng)
    },
    unsetPoi() {
      this.showingPoi = false // 点击边栏 POI X
      store.commit('tags/SET_POI', false) // 点击边栏 POI X
    },
    toggleLockPoi() {
      this.lockPoi = !this.lockPoi
    },
    toggleLockPhoto() {
      this.lockPhoto = !this.lockPhoto
    },
    toggleViewPoi() {
      if (this.showingPoi) {
        this.showingPoi = false
      } else {
        store.dispatch('ui/showPoi', this.poi)
        store.dispatch('map/panToPosition', this.poi.location)
      }
    },
    unsetPhoto() {
      this.showingPhoto = false // 点击边栏 Photo X
      store.commit('tags/SET_PHOTO', false) // 点击边栏 Photo X
    },
    toggleViewPhoto() {
      if (this.showingPhoto) {
        this.showingPhoto = false
      } else {
        store.dispatch('ui/showPhoto', this.photo.key) // 点击边栏 Photo View
      }
    },
    // clickLatSuffix() {
    //   if (this.latSuffix === 'N') {
    //     this.latSuffix = 'S'
    //   } else {
    //     this.latSuffix = 'N'
    //   }
    //   this.setLat()
    // },
    // clickLngSuffix() {
    //   if (this.lngSuffix === 'W') {
    //     this.lngSuffix = 'E'
    //   } else {
    //     this.lngSuffix = 'W'
    //   }
    //   this.setLng()
    // },
    hideStreetView() {
      this.showStreetView = false
    },
  },
}
</script>

<style lang="scss" scoped>
.form-control {
  font-size: 90% !important;
}

.card.compact > .card-header,
.card.compact > .card-body {
  padding: 10px;
  font-size: 90%;
}

.poi-image {
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 70px;
  width: 60px;
  height: 60px;
  background-color: #333;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
