<template>
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->
    <!-- <b-alert variant="danger" :show="product === undefined">
      <h4 class="alert-heading">Error fetching product data</h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link class="alert-link" :to="{ name: 'apps-e-commerce-shop' }">Shop</b-link>
        for other items.
      </div>
    </b-alert> -->

    <!-- Content -->
    <b-card no-body>
      <b-card-body>
        <validation-observer ref="photoForm">
          <b-form>
            <validation-provider #default="{ errors }" name="Photo URL" rules="required|url">
              <b-input-group class="input-group-merge">
                <b-form-input v-model="photoURL" placeholder="Photo URL" />
                <b-input-group-append is-text>
                  <b-link class="disabled text-secondary" @click="$refs.imageFile.click()">Upload Photo</b-link>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <input
              ref="imageFile"
              type="file"
              style="position: absolute; opacity: 0; z-index: -1"
              @change="selectedImage"
            />
            <b-row class="my-2">
              <!-- Left: Product Image Container -->
              <b-col cols="12" md="5" class="d-flex align-items-center justify-content-center mb-2 mb-md-0">
                <div v-if="uploadProgress <= 0" class="d-flex align-items-center justify-content-center">
                  <b-img :src="photoURL" alt="Photo" class="product-img" fluid />
                </div>
                <b-progress v-else v-model="uploadProgress" style="width: 70%" max="100" />
              </b-col>

              <!-- Right: Product Details -->
              <b-col cols="12" md="7">
                <!-- Product Name -->
                <!-- <h4>{{ product.name }}</h4> -->
                <b-form-group label="Photo Title" label-for="photo-title">
                  <validation-provider #default="{ errors }" name="Photo Title" rules="required">
                    <b-form-input id="photo-title" v-model="title" placeholder="Photo Title" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Product Brand -->
                <!-- <b-card-text class="item-company mb-0">
              <span>by</span>
              <b-link class="company-name">
                {{ product.brand }}
              </b-link>
            </b-card-text> -->

                <!-- Price And Ratings -->
                <!-- <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 class="item-price mr-1">${{ product.price }}</h4>
              <ul class="unstyled-list list-inline pl-1 border-left">
                <li v-for="star in 5" :key="star" class="ratings-list-item mr-25">
                  <feather-icon
                    icon="StarIcon"
                    size="18"
                    :class="[
                      { 'fill-current': star <= product.rating },
                      star <= product.rating ? 'text-warning' : 'text-muted',
                    ]"
                  />
                </li>
              </ul>
            </div> -->

                <!-- Avability -->
                <!-- <b-card-text>
              Available -
              <span class="text-success">In stock</span>
            </b-card-text> -->

                <b-form-group label="Keywords" label-for="photo-keywords">
                  <v-select
                    id="photo-keywords"
                    v-model="keywords"
                    dir="ltr"
                    multiple
                    label="title"
                    :options="photoKeywordsOption"
                  />
                  <b-link class="float-right" style="font-size: 90%" @click="toggleManageKeywords">
                    Manage Keywords
                  </b-link>

                  <b-collapse id="collapse-manage-keywords" v-model="manageKeywords" class="mt-2">
                    <b-form-textarea
                      id="manage-keywords"
                      v-model="myUserPhotoKeywords"
                      placeholder="Input your photo keywords here, use , to seperate "
                      rows="3"
                      @blur="doneManageKeywords"
                    />
                  </b-collapse>
                </b-form-group>

                <!-- Product Description -->
                <!-- <b-card-text>{{ product.description }}</b-card-text> -->
                <b-form-group label="Description" label-for="photo-description">
                  <b-form-textarea
                    id="photo-description"
                    v-model="description"
                    placeholder="Photo Description"
                    rows="3"
                    max-rows="6"
                  />
                </b-form-group>

                <!-- Product Meta [Free shpping, EMI, etc.] -->
                <!-- <ul class="product-features list-unstyled">
              <li v-if="product.hasFreeShipping">
                <feather-icon icon="ShoppingCartIcon" />
                <span>Free Shipping</span>
              </li>
              <li>
                <feather-icon icon="DollarSignIcon" />
                <span>EMI options available</span>
              </li>
            </ul> -->

                <!-- <hr /> -->

                <!-- Colors -->
                <!-- <div class="product-color-options">
              <h6>Colors</h6>
              <ul class="list-unstyled mb-0">
                <li
                  v-for="color in product.colorOptions"
                  :key="color"
                  class="d-inline-block"
                  :class="{ selected: selectedColor === color }"
                  @click="selectedColor = color"
                >
                  <div class="color-option" :class="`b-${color}`">
                    <div class="filloption" :class="`bg-${color}`" />
                  </div>
                </li>
              </ul>
            </div> -->

                <!-- <hr /> -->

                <div class="d-flex flex-column flex-sm-row pt-1">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="embedded ? 'outline-secondary' : 'primary'"
                    class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                    @click.prevent="savePhoto"
                  >
                    <feather-icon icon="SaveIcon" class="mr-50" />
                    <span>Save Photo</span>
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                    @click="isFavorite = !isFavorite"
                  >
                    <feather-icon icon="HeartIcon" class="mr-50" :class="{ 'text-danger': isFavorite }" />
                    <span>Favorite</span>
                  </b-button>

                  <div v-if="embedded" class="mr-0 ml-auto">
                    <b-button
                      v-if="photoId"
                      variant="primary"
                      class="mr-0 mr-sm-1 mb-1 mb-sm-0"
                      @click="$emit('gallery-use-photo', photoId)"
                    >
                      <!-- <feather-icon icon="HeartIcon" class="mr-50" :class="{ 'text-danger': isFavorite }" /> -->
                      <span>Use Photo</span>
                    </b-button>

                    <b-button
                      variant="outline-secondary"
                      class="mr-0 mr-sm-1 mb-1 mb-sm-0"
                      @click="$emit('gallery-photo-close')"
                    >
                      <!-- <feather-icon icon="HeartIcon" class="mr-50" :class="{ 'text-danger': isFavorite }" /> -->
                      <span>Back</span>
                    </b-button>
                  </div>

                  <!-- <b-dropdown variant="outline-secondary" no-caret toggle-class="btn-icon" class="btn-share" right>
                <template #button-content>
                  <feather-icon icon="Share2Icon" />
                </template>
                <b-dropdown-item
                  v-for="icon in ['FacebookIcon', 'TwitterIcon', 'YoutubeIcon', 'InstagramIcon']"
                  :key="icon"
                >
                  <feather-icon :icon="icon" />
                </b-dropdown-item>
              </b-dropdown> -->
                </div>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>

      <!-- Static Content -->
      <!-- <e-commerce-product-details-item-features /> -->

      <!-- Static Content -->
      <!-- Slider: Related Products -->
      <e-commerce-product-details-related-products v-if="false" />
    </b-card>
  </section>
</template>

<script>
// import { useRouter } from '@core/utils/utils'
import firebase from 'firebase/app'
import 'firebase/storage'
import store from '@/store'
// import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  // BCardText,
  BLink,
  BButton,
  // BDropdown,
  // BDropdownItem,
  // BAlert,
  // BFormFile,
  BForm,
  BFormGroup,
  BFormTextarea,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BCollapse,
  BProgress,
  VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required, url } from '@validations'

import mixinUI from '@/store/ui/mixin'
import mixinServer from '@/store/server/mixin'
import mixinDebug from './mixinDebug'

// import ECommerceProductDetailsItemFeatures from './parts/GalleryPhotoDetailsItemFeatures.vue'
import ECommerceProductDetailsRelatedProducts from './parts/GalleryPhotoDetailsRelatedPhotos.vue'
// import { useEcommerceUi } from './useGallery'

export default {
  name: 'ViewGalleryPhoto',
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    // BCardText,
    BLink,
    BButton,
    // BDropdown,
    // BDropdownItem,
    // BAlert,
    // BFormFile,
    BForm,
    BFormGroup,
    BFormTextarea,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    vSelect,
    BCollapse,
    BProgress,
    // SFC
    // ECommerceProductDetailsItemFeatures,
    ECommerceProductDetailsRelatedProducts,
  },
  mixins: [mixinUI, mixinServer, mixinDebug],
  props: {
    embedded: {
      type: Boolean,
      required: false,
      default: false,
    },
    photoKey: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      key: null,
      photoURL: null,
      title: null,
      description: null,
      keywords: [],
      isFavorite: true,
      manageKeywords: false,
      uploadProgress: 0,
      required,
      url,
    }
  },
  computed: {
    photoKeywordsOption() {
      return this.userPhotoKeywords.split(',').map(word => ({ title: word }))
    },
    myUserPhotoKeywords: {
      get() {
        return this.userPhotoKeywords
      },
      set(val) {
        this.tmpUserPhotoKeywords = val
      },
    },
    photoId() {
      return this.$route.params.photoId || this.photoKey
    },
  },
  watch: {
    photoId() {
      this.log('photoId changed', this.photoId)
      this.updatePhotoId()
    },
  },
  beforeMount() {
    this.updatePhotoId()
  },
  methods: {
    updatePhotoId() {
      if (this.photoId) {
        store
          .dispatch('server/fetchPhoto', { photoId: this.photoId })
          .then(photo => {
            this.log(photo)

            this.key = photo.key
            this.title = photo.t
            this.description = photo.desc
            this.photoURL = photo.url
            this.keywords = photo.ks.map(word => ({ title: word }))
            this.isFavorite = !!photo.if
          })
          .catch(e => {
            this.log(e)
            this.$router.push({ name: 'error-404' })
          })
      } else {
        this.key = null
        this.title = null
        this.description = null
        this.photoURL = null
        this.keywords = []
        this.isFavorite = false
      }
      this.manageKeywords = false
    },
    doneManageKeywords() {
      this.userPhotoKeywords = this.tmpUserPhotoKeywords
      this.timerDoneManageKeywords = setTimeout(() => {
        this.manageKeywords = false
      }, 200)
    },
    toggleManageKeywords() {
      clearTimeout(this.timerDoneManageKeywords)
      this.manageKeywords = !this.manageKeywords
    },
    savePhoto() {
      this.log('save photo')
      this.$refs.photoForm.validate().then(success => {
        if (success) {
          this.log(this.keywords)
          const data = {
            url: this.photoURL,
            t: this.title,
            if: this.isFavorite ? 1 : 0,
            desc: this.description || '',
            ks: this.keywords.map(item => item.title),
          }
          if (this.key) {
            store
              .dispatch('server/editPhoto', { key: this.key, data })
              .then(() => {
                this.toastS('saved successfully')
              })
              .catch(this.toastE)
          } else {
            store
              .dispatch('server/savePhoto', data)
              .then(r => {
                this.log(r)
                this.toastS('saved successfully')
                this.$router.push({ name: 'edit-photo', params: { photoId: r.key } })
              })
              .catch(this.toastE)
          }
        }
      })
    },
    selectedImage() {
      const file = this.$refs.imageFile.files[0]
      this.log(file)

      if (!file || !this.isUser) return

      if (file.type !== 'image/jpeg') {
        this.toastE({ message: 'should be jpeg image' })
        return
      }

      if (file.size > 1024 * 1024) {
        this.toastE({ message: 'size should less then 1M bytes' })
        return
      }

      // this.log(this.user)

      // this.showLoading()
      this.uploadProgress = 1
      const storageRef = firebase.storage().ref(`/${this.user.id}/${file.name}`).put(file)
      storageRef.on(
        'state_changed',
        snapshot => {
          this.uploadProgress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        },
        error => {
          this.toastE(error)
          this.uploadProgress = 0
        },
        () => {
          storageRef.snapshot.ref.getDownloadURL().then(firebaseUrl => {
            this.photoURL = firebaseUrl
            this.uploadProgress = 0
          })
        }
      )
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>

<style lang="scss" scoped>
.ecommerce-application .app-ecommerce-details .product-img {
  width: 100% !important;
}

.ecommerce-application .input-group-text {
  height: auto;
  border: 1px solid #d8d6de;
}
</style>
