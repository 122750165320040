import store from '@/store'

export default {
  computed: {
    user() {
      return store.state.server.user
    },
    isUser() {
      return !!this.user
    },
    isSuperUser() {
      return this.isUser && this.user.is_super_user
    },
    isAdmin() {
      return this.isUser && this.user.is_admin
    },
    userPhotoKeywords: {
      get() {
        return store.state.server.photoKeywords
      },
      set(val) {
        const keywords = val
          .split(',')
          .map(word => word.trim())
          .filter(word => word.length)
          .join(',')
        store.commit('server/SET_PHOTO_KEYWORDS', keywords)
        store.dispatch('server/postUserData', { photo_keywords: keywords })
      }
    }
  }
}
