import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
// eslint-disable-next-line import/no-cycle
import tags from './tags'
import map from './map'
import server from './server'
import ui from './ui'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    tags,
    map,
    server,
    ui
  },
  strict: process.env.DEV
})
