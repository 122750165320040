<template>
  <div class="map-video-container" :class="containerClass">
    <!-- video-on-map -->
    <!-- map-below-video -->

    <div class="video-container" :class="videoClass">
      <b-aspect :aspect="aspect">
        <slot name="video" />
      </b-aspect>
    </div>

    <div class="map-container" :class="mapClass">
      <slot name="map" />
    </div>

    <div v-if="showOverlay" class="overlay-container scroll-area">
      <slot name="overlay" />
    </div>

    <!-- map-on-video -->

    <!-- <div v-if="layout == 'map-on-video'" class="video-container full">
      <slot name="video" />
    </div>

    <div v-if="layout == 'map-on-video'" class="map-container float-br">
      <slot name="map" />
    </div> -->
  </div>
</template>

<script>
import store from '@/store'
import mixinUi from '@/store/ui/mixin'
import { BAspect } from 'bootstrap-vue'

export default {
  components: {
    BAspect,
  },
  mixins: [mixinUi],
  props: {
    layout: {
      type: String,
      required: true,
    },
    showOverlay: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      aspect: '16:9',
    }
  },
  computed: {
    containerClass() {
      return this.showSidebarRight ? 'sidebarOpen' : ''
    },
    videoClass() {
      if (this.layout === 'video-on-map' || !!this.showingPhoto) {
        /* || !!this.showingPoi */
        return 'float-br shadow'
      }
      if (this.layout === 'map-below-video') {
        return 'stack'
      }
      return ''
    },
    mapClass() {
      if (this.layout === 'video-on-map') {
        return 'full'
      }
      if (this.layout === 'map-below-video') {
        return 'stack'
      }
      return ''
    },
  },
  watch: {
    containerClass() {
      setTimeout(() => {
        store.dispatch('map/changeMapSize')
      }, 100)
    },
    mapClass() {
      setTimeout(() => {
        store.dispatch('map/changeMapSize')
      }, 100)
    },
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" >
.map-video-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* z-index: -1; */
  transition: all 0.5s ease;
}

/* .video-container {
  transition: all 0.3s ease;
} */

/* .map-container {
  transition: all 0.3s ease;
} */

.map-video-container.sidebarOpen {
  right: 420px;
}

.stack {
  position: relative;
  width: 100%;
  flex: 1;
}

.map-container.stack {
  min-height: 320px !important;
}

/* .video-container.stack {
  flex: 2;
} */

.full {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.float-br {
  position: absolute;
  bottom: 24px;
  right: 65px;
  width: 40%;
  z-index: 2;
}

.map-container.float-br {
  bottom: 60px;
  right: 0;
  height: 300px;
  width: 300px;
}

.float-bl {
  position: absolute;
  bottom: 40px;
  left: 0px;
  width: 40%;
  z-index: 99;
}

iframe {
  width: 100%;
  height: 100%;
}

.vue-map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

// .vertical-layout.vertical-menu-modern.menu-collapsed {
//   .overlay-container {
//     left: 0;
//   }
// }

.dark-layout {
  .overlay-container {
    background-color: #161d31;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f8f8;
  z-index: 5;

  .content {
    margin-left: 0;
  }

  .app-content {
    margin-left: 0 !important;
  }
}
</style>
