<template>
  <gmap-map
    ref="map"
    :center="center"
    :zoom="zoom"
    :options="mapOptions"
    @click="({ latLng, placeId, domEvent }) => $emit('click-map', latLng.toJSON(), placeId, domEvent)"
    @center_changed="latLng => $emit('change-map-center', latLng.toJSON())"
    @zoom_changed="val => $emit('change-zoom', val)"
  >
    <gmap-polyline
      v-for="(path, index) in mapPathLines"
      :key="`line${index}`"
      :path="path"
      :editable="false"
      :options="pathLine"
      @click="({ latLng }) => $emit('click-path', latLng.toJSON())"
    />

    <gmap-polyline
      v-for="(path, index) in mapPathDots"
      :key="`dot${index}`"
      :path="path"
      :editable="false"
      :options="pathDot"
      @click="({ latLng }) => $emit('click-path', latLng.toJSON())"
    />

    <gmap-marker
      v-for="(m, index) in poiMarkers"
      :key="`poiMarker${index}`"
      :position="m.position"
      :clickable="true"
      :draggable="showSidebarRightEditTags && !playVideo"
      :icon="poiMarkerIcon"
      @click="$emit('click-poi-marker', m)"
      @dragend="({ latLng }) => $emit('move-tag-marker', m, latLng.toJSON())"
    />

    <gmap-marker
      v-for="(m, index) in photoMarkers"
      :key="`photoMarker${index}`"
      :position="m.position"
      :clickable="true"
      :draggable="showSidebarRightEditTags && !playVideo"
      :icon="photoMarkerIcon"
      @click="$emit('click-photo-marker', m)"
      @dragend="({ latLng }) => $emit('move-tag-marker', m, latLng.toJSON())"
    />

    <gmap-marker
      v-for="(m, index) in povMarkers"
      :key="`povMarker${index}`"
      :position="m.position"
      :clickable="true"
      :draggable="showSidebarRightEditTags && !playVideo"
      :icon="povMarkerIcon"
      @click="$emit('click-pov-marker', m)"
      @dragend="({ latLng }) => $emit('move-tag-marker', m, latLng.toJSON())"
    />

    <gmap-polyline
      v-for="(path, index) in mapPathVideos"
      :key="`line${index}`"
      :path="path"
      :editable="false"
      :options="pathLineOptions(index)"
      @click="$emit('click-video', index)"
      @mouseover="focusIndex = index"
      @mouseout="focusIndex = -1"
    />

    <gmap-marker
      v-for="(m, index) in videoMarkers"
      :key="`videoMarker${index}`"
      :position="m.position"
      :clickable="true"
      :draggable="false"
      :icon="pathMarkerIcon(index)"
      :z-index="focusIndex === index ? 1 : 0"
      @click="$emit('click-video', index)"
      @mouseover="focusIndex = index"
      @mouseout="focusIndex = -1"
    />

    <div v-if="showSidebarRightEditTags">
      <gmap-marker
        v-for="(m, index) in selectedMarkers"
        :key="`selectedMarker${index}`"
        :position="m.position"
        :clickable="true"
        :draggable="showSidebarRightEditTags && !playVideo"
        :icon="selectedMarkerIcon"
        @click="$emit('click-tag-marker', m)"
        @dragend="({ latLng }) => $emit('move-tag-marker', m, latLng.toJSON())"
      />
    </div>

    <GmapCluster v-if="showSidebarRightEditTags && !playVideo">
      <gmap-marker
        v-for="(m, index) in otherMarkers"
        :key="`otherMarker${index}`"
        :position="m.position"
        :clickable="true"
        :draggable="showSidebarRightEditTags && !playVideo"
        :icon="otherMarkerIcon"
        @click="$emit('click-tag-marker', m)"
        @dragend="({ latLng }) => $emit('move-tag-marker', m, latLng.toJSON())"
      />
    </GmapCluster>

    <gmap-marker
      v-if="theMarker"
      key="theMarker"
      :position="theMarker.position"
      :icon="pathMarkerIcon()"
      :clickable="false"
      :draggable="true"
      @dragend="({ latLng }) => $emit('move-the-marker', latLng.toJSON())"
    />

    <!-- <poi-info-window /> -->
    <gmap-info-window
      v-if="showingPoi"
      :options="{
        maxWidth: 300,
        pixelOffset: { width: 0, height: 0 },
      }"
      :position="showingPoi.location"
    >
      {{ showingPoi.n }}&nbsp;&nbsp;
      <a :href="showingPoi.url" target="_blank">Open...</a>
    </gmap-info-window>

    <google-map-button v-for="(m, index) in mapButtons" :key="`mapButtons${index}`">
      <b-button v-b-tooltip.hover.bottom="m.tooltip" variant="primary" class="btn-icon" @click="$emit(m.event)">
        <feather-icon :icon="m.icon" />
      </b-button>
    </google-map-button>
  </gmap-map>
</template>

<script>
import store from '@/store'

import GmapCluster from 'vue2-google-maps/src/components/cluster'

import mixinUi from '@/store/ui/mixin'
import mixinMap from '@/store/map/mixin'
// import mixinDebug from '../mixinDebug'

import { BButton, VBTooltip } from 'bootstrap-vue'
import GoogleMapButton from './GoogleMapButton.vue'

const mapPinRed = require('@/assets/images/marker/map-pin-red.png')
const mapPinOrange = require('@/assets/images/marker/map-pin-orange.png')

export default {
  name: 'GoogleMap',
  components: {
    BButton,
    GmapCluster,
    GoogleMapButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [mixinUi, mixinMap],
  props: {
    center: {
      type: Object,
      required: false,
      default: () => ({ lat: 0, lng: 0 }),
    },
    zoom: {
      type: Number,
      required: false,
      default: 12,
    },
  },
  data() {
    return {
      map: null,
      mapOptions: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false,
        disableDefaultUi: false,
        clickableIcons: true,
        keyboardShortcuts: false,
      },
      pathLine: {
        strokeColor: '#00acc1',
        strokeWeight: 5,
      },
      pathDot: {
        strokeColor: '#00acc1',
        strokeWeight: 5,
        strokeOpacity: 0,
        icons: [
          {
            icon: {
              path: 'M 0,-0.5 0,0.5',
              strokeOpacity: 1,
              scale: 5,
            },
            offset: '0',
            repeat: '20px',
          },
        ],
      },
      selectedMarkerIcon: {
        // eslint-disable-next-line global-require
        url: require('@/assets/images/marker/cycle18g.png'),
        anchor: { x: 9, y: 9 },
      },
      otherMarkerIcon: {
        // eslint-disable-next-line global-require
        url: require('@/assets/images/marker/cycle18.png'),
        anchor: { x: 9, y: 9 },
      },
      poiMarkerIcon: {
        // eslint-disable-next-line global-require
        url: require('@/assets/images/marker/poi-marker.png'),
        anchor: { x: 15, y: 15 },
      },
      povMarkerIcon: {
        // eslint-disable-next-line global-require
        url: require('@/assets/images/marker/pov-marker.png'),
        anchor: { x: 15, y: 15 },
      },
      photoMarkerIcon: {
        // eslint-disable-next-line global-require
        url: require('@/assets/images/marker/photo-marker.png'),
        anchor: { x: 15, y: 15 },
      },

      focusIndex: -1,
    }
  },
  computed: {},
  watch: {
    focusIndex() {
      this.$emit('focus-index', this.focusIndex)
    },
    // showingPoi() {
    //   if (this.showingPoi) {
    //     this.infoWindow.position = this.showingPoi.location
    //     this.infoWindow.template = `${this.showingPoi.n}&nbsp;&nbsp;<a href=${this.showingPoi.url} target="_blank">Open...</a>`
    //     this.infoWindow.open = true
    //     // this.popup = L.popup({ offset: L.point(0, 0), autoPan: false, closeButton: true, closeOnClick: false })
    //     //   .setLatLng(this.showingPoi.location)
    //     //   .setContent(`${this.showingPoi.n}&nbsp;&nbsp;<a href=${this.showingPoi.url} target="_blank">Open...</a>`)
    //     //   .openOn(this.map)
    //   } else {
    //     this.infoWindow.open = false
    //   }
    // },
  },
  mounted() {
    this.$refs.map.$mapPromise.then(map => {
      this.map = map
      store.commit('map/SET_GOOGLE_MAP', map)

      const streetView = map.getStreetView()
      streetView.addListener('visible_changed', () => {
        if (!streetView.getVisible()) {
          store.commit('tags/SET_POV', false) // 当街景不显示时，取消右边栏 POV？
          this.lockStreetView = false
        }
        store.commit('ui/TOGGLE_STREET_VIEW', streetView.getVisible())
      })
      streetView.addListener('position_changed', () => {
        if (this.showSidebarRightEditTags && this.showStreetView) {
          store.dispatch('tags/setLocation', streetView.getPosition().toJSON())
        }
      })
      streetView.addListener('pov_changed', () => {
        if (this.showSidebarRightEditTags && this.showStreetView) {
          const pov = streetView.getPov()
          const heading = Math.floor(pov.heading * 100) / 100
          const pitch = Math.floor(pov.pitch * 100) / 100
          const zoom = Math.floor(pov.zoom * 100) / 100
          store.commit('tags/SET_POV', { heading, pitch, zoom })
        }
      })

      this.$emit('map-inited')
    })
  },
  methods: {
    pathLineOptions(index) {
      return {
        strokeColor: index === this.focusIndex ? '#F8954D' : '#00acc1',
        strokeWeight: 6,
        zIndex: index === this.focusIndex ? 1 : 0,
      }
    },
    pathMarkerIcon(index) {
      return {
        // eslint-disable-next-line global-require
        url: index === this.focusIndex ? mapPinOrange : mapPinRed,
        anchor: { x: 15, y: 45 },
        // size: { width: 40, height: 40, f: 'px', b: 'px' },
        // scaledSize: { width: 10, height: 10, f: 'px', b: 'px' },
      }
    },
  },
}
</script>

<style lang="scss">
</style>
