import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import { log } from '@/utils'

import VideoMap from '@/views/ViewVideoMap.vue'
import IndexMap from '@/views/ViewIndexMap.vue'

import EditPhotoPageFunctions from '@/views/parts/EditPhotoPageFunctions.vue'

Vue.use(VueRouter)

Vue.mixin({
  beforeRouteEnter(to, from, next) {
    if (to.query.token) {
      store
        .dispatch('server/retrieveToken', to.query.token)
        .then(() => {
          log('token retrived from server')
        })
        .catch(err => {
          log(err)
        })
        .finally(() => {
          next({ name: to.name, params: to.params })
        })
    } else {
      store
        .dispatch('server/getUser')
        .then(() => {
          log('Set User', store.state.server.user.id)
          log(store.state.server.user)
        })
        .catch(err => {
          log(err)
        })
        .finally(() => {
          next()
        })
    }
  }
})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'world',
      component: IndexMap,
      props: {
        continent: 'World'
      },
      meta: {}
    },
    {
      path: '/africa',
      name: 'africa',
      component: IndexMap,
      props: {
        continent: 'Africa'
      },
      meta: {}
    },
    {
      path: '/asia',
      name: 'asia',
      component: IndexMap,
      props: {
        continent: 'Asia'
      },
      meta: {}
    },
    {
      path: '/australia',
      name: 'australia',
      component: IndexMap,
      props: {
        continent: 'Australia'
      },
      meta: {}
    },
    {
      path: '/europe',
      name: 'europe',
      component: IndexMap,
      props: {
        continent: 'Europe'
      },
      meta: {}
    },
    {
      path: '/north-america',
      name: 'north-america',
      component: IndexMap,
      props: {
        continent: 'North America'
      },
      meta: {}
    },
    {
      path: '/south-america',
      name: 'south-america',
      component: IndexMap,
      props: {
        continent: 'South America'
      },
      meta: {}
    },
    {
      path: '/video/:id',
      name: 'video',
      component: VideoMap,
      meta: {}
    },
    {
      path: '/gallery',
      name: 'gallery',
      component: () => import('@/views/ViewGallery.vue'),
      meta: {
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: 'Gallery',
        breadcrumb: [
          {
            text: 'Gallery',
            active: true
          }
        ]
      }
    },
    {
      path: '/videos',
      name: 'videos',
      component: () => import('@/views/ViewYouTube.vue'),
      meta: {
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: 'Videos',
        breadcrumb: [
          {
            text: 'Videos',
            active: true
          }
        ]
      }
    },
    {
      path: '/gallery/photo/:photoId',
      name: 'edit-photo',
      component: () => import('@/views/ViewGalleryPhoto.vue'),
      meta: {
        contentClass: 'ecommerce-application',
        pageTitle: 'Edit Photo',
        breadcrumb: [
          {
            text: 'Gallery',
            to: { name: 'gallery' }
          },
          {
            text: 'Edit Photo',
            active: true
          }
        ],
        pageComponent: EditPhotoPageFunctions
      }
    },
    {
      path: '/videos/video/:videoId',
      name: 'edit-video',
      component: () => import('@/views/ViewYouTubeVideo.vue'),
      meta: {
        contentClass: 'ecommerce-application',
        pageTitle: 'Manage Video',
        breadcrumb: [
          {
            text: 'Videos',
            to: { name: 'videos' }
          },
          {
            text: 'Manage Video',
            active: true
          }
        ]
      }
    },
    {
      path: '/gallery/photo',
      name: 'new-photo',
      component: () => import('@/views/ViewGalleryPhoto.vue'),
      meta: {
        contentClass: 'ecommerce-application',
        pageTitle: 'New Photo',
        breadcrumb: [
          {
            text: 'Gallery',
            to: { name: 'gallery' }
          },
          {
            text: 'New Photo',
            active: true
          }
        ]
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('@/views/error/Error.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
