import store from '@/store'

export default {
  computed: {
    isEdited() {
      return store.state.tags.isEdited
    },
    canEdit() {
      return store.getters['tags/canEdit']
    },
    tags() {
      return store.state.tags.tags
    },
    tag() {
      return store.getters['tags/currentTag']
    },
    workingTag() {
      return store.getters['tags/workingTag']
    },
    totalSeconds() {
      return store.state.tags.totalSeconds
    },
    currentIndex() {
      return store.state.tags.currentIndex
    },
    currentSeconds() {
      return store.state.tags.currentSeconds
    },
    currentLocation() {
      return store.state.tags.location
    },
    poi() {
      return store.state.tags.poi
    },
    lockPoi: {
      get() {
        return store.state.tags.lockPoi
      },
      set(val) {
        store.commit('tags/SET_LOCK_POI', val)
      }
    },
    pov() {
      return store.state.tags.pov
    },
    photo() {
      return store.state.tags.photo
    },
    lockPhoto: {
      get() {
        return store.state.tags.lockPhoto
      },
      set(val) {
        store.commit('tags/SET_LOCK_PHOTO', val)
      }
    },
    adjustTime: {
      get() {
        return store.state.tags.editTimeMode
      },
      set(val) {
        store.commit('tags/TOGGLE_EDIT_TIME_MODE', val)
      }
    }
  }
}
